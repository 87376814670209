<style scoped lang="less">

    .singleRowProduct-module{
        
        .title{
            padding: 10px 15px;
            font-size: 14px;
            background: #fff;
			word-break: break-word;
			display: flex;
			align-items: center;
        }
        .box{
			flex: 1 1 auto;
            padding: 0 15px;
            height: 40px;
            line-height: 40px;
            background: #f5f5f5;
            border-radius: 20px;
        }
        .scan{
			flex: 0 0 auto;
			width: 36px;
			margin-left: 10px;
			
			img{
				display: block;
				width: 100%;
			}
		}
    }
    .haveSpace{
        margin-bottom: 10px;
    }
    
</style>

<template>
    <div class="singleRowProduct-module"  @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;" :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        <!-- <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  /> -->
        
        <div class="title"> <!-- @click="edit();data.visible=true" -->
            <div class="box">
				<i class="el-icon-search" style="font-size: 20px;color: #999;vertical-align:-2px;padding-right:5px"></i>
                <!-- <Icon type="ios-search" size=20 color="#999" style="vertical-align:-2px"></Icon> -->
                <span v-if="data.text">{{data.text}}</span>
                <span style="color: #ccc" v-else>请输入商品名称</span>
            </div>
            <div class="scan" v-if="data.showScan">
				<img src="https://api.static.huibogoulive.cn/images/melyshop/scan.png" />
			</div>
        </div>
		
		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>

    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';

export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
    },
    watch: {
        
    }
};
</script>

