<style scoped lang="less">
    .search{
        flex: 0 0 auto;
        margin-bottom: 10px;
    }
    .tab-box{
        flex: 1 1 auto;
        margin-bottom: 10px;
    }
    .pagination{
        flex: 0 0 auto;
    }
</style>
<template>
    <el-dialog
        :visible="show"
        width='990px'
        title="选择直播间"
		:append-to-body="true"
        @close="cancel">
 
		<div class="search">
			<span style="padding-right: 10px;">关键字：</span>
		    <el-input v-model="keyword" style="width:200px;margin-right:10px" size="mini" placeholder="直播间Id/直播标题"/>
		    <el-button type="primary" size="mini" @click="pageIndex=1;getData();">搜索</el-button>
			<el-button type="primary" size="mini" @click="getNewBroad();">获取最新直播列表</el-button>
		</div>

        <div class="tab-box">
            <el-table :data="data" v-loading="loading" size="mini" :row-key="getRowKey" ref="tab" @selection-change="handleSelectionChange">
            	<el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
            	<el-table-column prop="RoomId" label="直播间ID" width="80px"></el-table-column>
				<el-table-column prop="AnchorNickName" label="主播" width="120px"></el-table-column>
				<el-table-column prop="RoomName" label="直播标题" width="290px"></el-table-column>
            	<el-table-column label="直播时间" width="300px">
            		<template slot-scope="scope">
            			<span>{{scope.row.StartTime}}</span>
						至
						<span>{{scope.row.EndTime}}</span>
            		</template>
            	</el-table-column>
				<el-table-column prop="StateValue" label="直播状态" width="100px"></el-table-column>
            </el-table>
        </div>

        <div class="pagination">
            <el-pagination :current-page="pageIndex" :total="total" :page-size="pageSize" @current-change="pageChange"></el-pagination>
        </div>
        
        <div slot="footer">
            <el-button type="primary" @click="btnSure">确定</el-button>
        </div>

    </el-dialog>
</template>
<script>

import {
		liveRoomList,
		liveRoomRefreshCache
	} from '@/api/goods'
import config from '@/config/index'

export default {
    components: {
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        isSingle: { //是否是单选
            type: Boolean,
            default: false
        },
        selectedList: {
            type: Array,
            default: ()=>{
                return []
            }
        }
    },
    data () {
        return {
            show: false,
            keyword: '',
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            data: [],
            loading: false,
            selectionData: [],
			imgUrl: config.IMG_BASE
        };
    },
    methods: {
        cancel(){
            this.$emit('cancel');
        },
        async getData(){
            try {
            	this.loading = true
            
            	let data = {
            		KeyWords: this.keyword, // 搜索关键字
            		Skip: (this.pageIndex - 1) * this.pageSize, // 略过数据
            		Take: this.pageSize, // 取的数据
            	}
            	let result = await liveRoomList(data);
            	this.total = result.Result.Total;
            	this.data = (result.Result.Results || []).map(item=>{
					item.timeStr = this.getTimeStr(item.StartTime);
					return item;
				});
            
            } catch (e) {
            	//TODO handle the exception
            	console.log(e)
            } finally {
            	this.loading = false
            }

        },
		getTimeStr(time){
			
			var str = '';
			
			var split = time.split(' ');
			var time1 = split[0].split('-');
			var time2 = split[1].split(':');
			
			//今天年月日
			var todayYear = new Date().getFullYear();
			var todayMonth = new Date().getMonth();
			var todayDay = new Date().getDate();
			
			//开始时间的年月日
			var date1Year = +time1[0];
			var date1Month = (+time1[1])-1;
			var date1Day = +time1[2];
			
			if (new Date(todayYear, todayMonth, todayDay).getTime() == new Date(date1Year, date1Month, date1Day).getTime()){
				console.log('今天')
				str = '今天 '+[time2[0], time2[1]].join(':')+'开播';
			}else if (new Date(todayYear, todayMonth, todayDay+1).getTime() == new Date(date1Year, date1Month, date1Day).getTime()){
				str = '明天 '+[time2[0], time2[1]].join(':')+'开播';
			}else{
				str = (date1Month+1) +'月'+date1Day+'日 '+[time2[0], time2[1]].join(':')+'开播';
			}
			return str;
		}, 
		getRowKey(row){
			return row.Id
		},
        pageChange(value){
            this.pageIndex = value;
            this.getData();
        },
		// 获取最新直播
		async getNewBroad() {
			try {
				let result = await liveRoomRefreshCache()
				if (result.IsSuccess) {
					this.$message({
						showClose: true,
						type: 'success',
						message: '操作成功!'
					});
				}
			} catch (e) {
				//TODO handle the exception
				console.log(e)
			} finally {
				this.getData()
			}
		
		},
		//当前页全选
		handleSelectionChange(val) {
			this.selectionData = val;
		},
        // selectionChange(selection){
        //     this.selectionData = selection;
        // },
        // selectedRow(record, row){
        //     this.singleData = row;
        // },
        btnSure(){
            // if (this.isSingle && this.selectedData.length > this.disableList.length+1){
            //     this.$Message.error('修改产品只能单选！');
            //     return;
            // }

            this.$emit('change', this.selectionData);
            this.cancel();
        }
    },
    watch: {
        visible(value){
            this.show = value;

            if (value){
				if (this.$refs['tab']){
					this.$refs['tab'].clearSelection()
					this.selectedList.map(item => {
						this.$refs['tab'].toggleRowSelection(item, true);
					})
				}
                this.getData();
            }
        }
    },
    mounted () {
         
    },
    created () {

    },
    dispatch () {
        
    }
};
</script>
