<style scoped lang="less">
	.search-box{
        margin-bottom: 20px;
    }
    .table-box{
        margin-bottom: 15px;
    }
	
	.shop-name{
		display: flex;
	}
	.shop-name .img{
		flex: 0 0 auto;
	}
	.shop-name .name{
		flex: 1 1 auto;
		overflow: hidden;
		display: flex;
		align-items: center;
	}
</style>
<template>
	<el-dialog :visible="show" width='990px' title="选择积分兑换商品" @close="cancel">

		<div class="search-box">
			<span style="padding-right: 10px;">关键字：</span>
			<el-input v-model="keywords" style="width:200px;margin-right:10px" size="mini" placeholder="商品名称、编码" />
			<el-button type="primary" size="mini" @click="search">搜索</el-button>
		</div>

		<div class="table-box">
			<el-table :data="data" v-loading="loading" size="mini" :row-key="getRowKey" ref="tab" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
				<el-table-column label="商品" width="490px">
					<template slot-scope="scope">
						<div class="shop-name" style="overflow: hidden;">
							<div class="img" style="width: 66px;height: 66px;">
								<img :src="imgUrl + scope.row.ImgUrl" style="display: block;width: 100%;" />
							</div>
							<div class="name" style="margin-left: 10px;">
								<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="兑换价" width="200px">
					<template slot-scope="scope">
						{{scope.row.PointCount}}积分<span v-if="scope.row.PointPrice">+{{scope.row.PointPrice}}元</span>
					</template>
				</el-table-column>
				<el-table-column prop="RemainCount" label="剩余兑换数量" width="200px"></el-table-column>
			</el-table>
		</div>

		<el-pagination :current-page="pageIndex" :total="total" :page-size="pageSize" @current-change="pageChange"></el-pagination>

		<div slot="footer">
			<el-button type="primary" @click="btnSure">确定</el-button>
		</div>

	</el-dialog>
</template>
<script>
	import {
		editSelectPointExchange
	} from '../../api/pageManage.js';
	import config from '@/config/index'
	export default {
		components: {

		},
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			isSingle: { //是否是单选
				type: Boolean,
				default: false
			},
			selectedList: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		data() {
			return {
				keywords: '',
				pageIndex: 1,
				pageSize: 10,
				total: 0,
				loading: false,
				selectedData: [],
				singleData: {},
				data: [],
				show: false,
				imgUrl: config.IMG_BASE
			};
		},
		methods: {
			async getData() {
				const params = {
					Keywords: this.keywords,
					Skip: (this.pageIndex - 1) * this.pageSize,
					Take: this.pageSize,
					Type: 0
				}

				this.loading = true;

				const result = await editSelectPointExchange(params);

				this.data = result.Result.Results;
				this.total = result.Result.Total;
				this.loading = false;

				if (!this.isReady) {
					if (this.$refs['tab']) {
						this.selectedList.map(item => {
							this.$refs['tab'].toggleRowSelection(item, true);
						})
						this.isReady = true;
					}
				}

			},
			getRowKey(row) {
				return row.ActivityFullId
			},
			cancel() {
				this.$emit('cancel');
			},
			pageChange(value) {
				this.pageIndex = value;
				this.getData();
			},
			search() {
				this.pageIndex = 1;
				this.getData();
			},
			//当前页全选
			handleSelectionChange(val) {
				this.selectedData = val;
			},
			selectedRow(record, row) {
				this.singleData = row;
			},
			btnSure() {
				// if (this.isSingle && this.selectedData.length > this.disableList.length+1){
				//     this.$Message.error('修改产品只能单选！');
				//     return;
				// }

				this.$emit('change', this.selectedData);
				this.cancel();
			}
		},
		watch: {
			visible(value) {
				this.show = value;

				if (value) {
					if (this.$refs['tab']) {
						this.$refs['tab'].clearSelection()
						this.selectedList.map(item => {
							this.$refs['tab'].toggleRowSelection(item, true);
						})
					}
					this.getData();
				}
			}
		},
		mounted() {
			// this.selectedList.map(item => {
			// 	this.$refs['tab'].toggleRowSelection(item, true);
			// })
			// this.getData();
		},
		created() {

		},
		dispatch() {

		}
	};
</script>
