<style scoped lang="less">

    .module-content{
        
        .hot-box{
            position: relative;
            user-select: none;

            .bg{
                display: block;
                width: 100%;
                pointer-events: none;
            }
        }
        
    }
    .haveSpace{
        margin-bottom: 10px;
    }

    .area-box{
        position: absolute;
        opacity: .5;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
    }
    
</style>

<template>
    <div class="module-content" @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;" :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        <!-- <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false" @move="handelCanMove" /> -->
        
        <div class="hot-box" @mousedown="mousedown" @mousemove="mousemove" @mouseup="mouseup"  > <!-- @click="edit();data.visible=true" -->
            <img v-if="data.mainUrl===''" class="bg" :src="$store.state.pageManageConfig.staticImage.noPic1" />
            <img v-else class="bg" id="cropper-box" :src="data.mainUrl" />

            <div class="area-box" :style="item.areaData" :key="item.uid" v-for="(item, index) in data.list">区域{{index+1}}</div>
        </div>
		
		<div class="cover" v-if="data.visible && !canMove" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>
		

    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';

import Cropper from 'cropperjs';
import './cropper.min.css';


export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
            canMove: false,
            canDraw: false,
            moveData: {},
            moveIndex: null,
            styleList: []
        };
    },
	watch:{
		data(){
			console.log(this.data)
		}
	},
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        close(){
            this.$emit('visibleChange', false)
        },
        handelCanMove(record, index){
            this.canMove = true;
            this.canDraw = false;
            this.moveData = record;
            this.moveIndex = index;
        },
        mousedown(event){
            if (!this.canMove) return;

            this.x = event.offsetX;
            this.y = event.offsetY;
            this.pagex = event.pageX;
            this.pagey = event.pageY;

            this.canDraw = true;

            this.data.list[this.moveIndex].areaData = {
                left: this.x + 'px',
                top: this.y + 'px',
                width: '1px',
                height: '1px',
                background: this.moveData.color
            }
        },
        mousemove(event){
           
            if (!this.canMove || !this.canDraw) return;

            event.preventDefault();

            let curx = event.pageX - this.pagex;
            let cury = event.pageY - this.pagey;

            this.data.list[this.moveIndex].areaData.width = Math.abs(curx) + 'px';
            this.data.list[this.moveIndex].areaData.height = Math.abs(cury) + 'px';
            this.data.list[this.moveIndex].areaData.left = (curx < 0 ? Math.abs(curx+this.x) : this.x) + 'px';
            this.data.list[this.moveIndex].areaData.top = (cury < 0 ? Math.abs(cury+this.y) : this.y) + 'px';

        },
        mouseup(){
            this.canMove = false;
        },

    },
    mounted () {
        
    },
    watch: {
    }
};
</script>

