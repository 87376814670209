<style scoped lang="less">

    .singleRowProduct-module{
        
        .title{
            padding: 20px 25px;
            font-size: 16px;
            background: #fff;
            height: 210px;
        }
        
    }
    .haveSpace{
        margin-bottom: 10px;
    }
	
	.notice-wraper{
		background: #fffae7;
		height: 30px;
		display: flex;
		align-items: center;
		overflow: hidden;
		padding: 0 10px;
		
		img{
			flex: 0 0 auto;
			display: block;
			width: 16px;
			height: 16px;
		}
		
		.txt{
			flex: 1 1 auto;
			overflow: hidden;
			color: #666;
			font-size: 14px;
			margin-left: 5px;
			white-space: nowrap;
		}
	} 
    
</style>

<template>
    <div class="singleRowProduct-module" @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;" :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        <!-- <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  /> -->
        
       <!-- <div class="title" >{{data.text||"请输入公告内容"}}</div> --> <!-- @click="edit();data.visible=true" -->
	   <div class="notice-wraper">
		   <img src="https://api.static.huibogoulive.cn/images/melyshop/icon_horn.png"/>
		   <div class="txt">{{data.text||"输入公告内容，如果过长，将会在手机上滚动显示"}}</div>
	   </div>
		
		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>
    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';

export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
    },
    watch: {
        
    }
};
</script>

