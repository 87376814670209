<style scoped lang="less">
	.product {
		background-color: #fff;
		.item {
			display: flex;
			margin-top: 5px;
			padding-left: 10px;
			background: #fff;

			&:first-child {
				margin-top: 0;
			}

			.img {
				position: relative;
				flex: 0 0 auto;
				width: 140px;
				height: 140px;
				overflow: hidden;
				border-radius: 5px;

				>img {
					display: block;
					width: 100%;
				}
				
				.kill-icon {
					position: absolute;
					left: 0;
					top: 0;
					width: 55px;
					height: 22px;
				}

				.time {
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 24px;
					line-height: 24px;
					background: #ff547b;
					font-size: 12px;
					color: #fff;
					text-align: center;
				}
			}

			.right {
				flex: 1 1 auto;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				margin-left: 10px;
				margin-right: 10px;

				.top {
					overflow: hidden;
				}

				.name {
					font-size: 16px;
					overflow: hidden;
					max-height: 42px;
					line-height: 21px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;

					.tag {
						display: inline-block;
						margin-right: 5px;
						vertical-align: 2px;
						padding: 0 10px;
						height: 16px;
						line-height: 16px;
						color: #fff;
						background: #ff547b;
						border-radius: 8px;
						font-size: 12px;
						text-align: center;
					}

					.name-box {
						display: inline;
					}
				}

				.maidian {
					font-size: 12px;
					color: #999;
					margin-top: 4px;
					height: 30px;
					line-height: 15px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}

				.sell-count {
					.income-btn {
						border-radius: 4px;
						border: 1px solid #FE0D30;
						height: 24px;
						display: flex;
						flex-direction: row;
						align-items: center;
						width: 71px;

						.income-icon {
							width: 26px;
							height: 24px;
						}

						.income-right {
							width: 44px;
							height: 24px;
							display: flex;
							align-items: center;
							color: #FE0D30;
							justify-content: center;

							.income-fu {
								font-size: 10px;
								justify-content: flex-end;
								height: 18px;
								display: flex;
								flex-direction: column;
							}

							.income-money {
								font-size: 15px;
								font-weight: 600;
								text-align: center;
							}
						}
					}
				}

				.price {
					display: flex;

					.left {
						font-size: 15px;
						color: #ff547b;
						flex: 1 1 auto;
						margin-right: 10px;
						white-space: nowrap;
						display: flex;
						align-items: center;

						.old {
							font-size: 12px;
							color: #999;
							padding-top: 2px;
							margin-left: 5px;
							text-decoration: line-through;
						}
					}

					.btn {

						width: 80px;
						height: 32px;
						line-height: 32px;
						text-align: center;
						font-size: 18px;
						color: #fff;
						border-radius: 4px;
						margin-bottom: 4px;
						font-weight: 500;
						background: linear-gradient(167deg, #FE8129 0%, #FE0D30 100%) !important;
					}
				}
			}
		}

		.item-row {
			width: 111px;
			margin-top: 5px;
			background: #fff;
			margin-left: 10px;
			.img {
				position: relative;
				flex: 0 0 auto;
				width: 111px;
				height: 111px;
				overflow: hidden;
				border-radius: 5px;

				>img {
					display: block;
					width: 100%;
				}
				.kill-icon {
					position: absolute;
					left: 0;
					top: 0;
					width: 55px;
					height: 22px;
				}
			}
			.right {
				flex: 1 1 auto;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
			
				.top {
					overflow: hidden;
				}
			
				.name {
					margin-top: 5px;
					font-size: 12px;
					overflow: hidden;
					max-height: 42px;
					line-height: 21px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
			
					.tag {
						display: inline-block;
						margin-right: 5px;
						vertical-align: 2px;
						padding: 0 10px;
						height: 16px;
						line-height: 16px;
						color: #fff;
						background: #ff547b;
						border-radius: 8px;
						font-size: 12px;
						text-align: center;
					}
			
					.name-box {
						display: inline;
					}
				}
			
				.price {
					display: flex;
					margin-top: 6px;
					.left {
						font-size: 15px;
						color: #ff547b;
						flex: 1 1 auto;
						margin-right: 10px;
						white-space: nowrap;
						display: flex;
						align-items: center;
			
						.old {
							font-size: 12px;
							color: #999;
							padding-top: 2px;
							margin-left: 5px;
							text-decoration: line-through;
						}
					}
				}
				.btn {
					width: 111px;
					height: 28px;
					line-height: 28px;
					text-align: center;
					font-size: 14px;
					color: #fff;
					margin-top: 6px;
					border-radius: 4px;
					margin-bottom: 4px;
					font-weight: 500;
					background: linear-gradient(167deg, #FE8129 0%, #FE0D30 100%) !important;
				}
			}
		}

		.shouqing {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, .4);
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				display: block;
				width: 50%;
				transform: rotate(-30deg);
			}
		}
	}

	.haveSpace {
		margin-bottom: 10px;
	}

	.skill-tabs {
		display: flex;
		flex-direction: row;
		background-color: #ff547b;
		font-size: 12px;
		padding-top: 5px;
		justify-content: space-between;
		padding-left: 40px;
		padding-right: 40px;

		.skill-tab {
			background-color: #fff;
			border-radius: 5px 5px 0 0;
			color: #ff547b;
			width: 120px;
			text-align: center;
			align-items: center;
			padding: 6px 0;
		}

		.skill-tab-unchecked {
			color: #fff;
			width: 100px;
			text-align: center;
			align-items: center;
			padding: 6px 0;
		}
	}

	.tabsScroll {
		overflow-x: auto;
		overflow-y: hidden;
		white-space: nowrap;
		padding-bottom: 5px;
		width: 100%;

		.tab {
			display: inline-block;
			font-size: 15px;
			text-align: center;
			margin-right: 17px;

			&:last-child {
				padding-right: 17px;
			}

			.skill-time {
				color: #0A0A0A;
			}

			.time-tip {
				font-weight: 500;
				color: #0A0A0A;
				font-size: 14px;
				margin-top: 2px;
			}

			.tipCurrent {
				border-radius: 10px;
				height: 20px;
				padding: 0 10px;
				line-height: 20px;
				color: #fff;
				background-color: #FE0D30;
			}
		}
	}
</style>

<template>
	<div class="singleRowProduct-module" @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;"
	:class="{haveSpace: !data.hideSpace}">
		<module-name :name="name"></module-name>
		<controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')"
		@remove="$emit('remove')"></controler>
		<div class="tabsScroll" style="display: flex;align-items: center;background-color: #fff;">
			<div style="display: flex;align-items: center;padding: 8px 17px 8px 11px">
				<div style="text-align: center;font-weight: 600;font-family: PingFangSC-Semibold, PingFang SC;color: #0A0A0A;font-size: 18px;">
					<div>限时</div>
					<div>秒杀</div>
				</div>
			</div>
			<div class="tab">
				<div class="skill-time" style="font-weight: bold;font-size: 18px;">08:00</div>
				<div class="time-tip tipCurrent">进行中</div>
			</div>
			<div class="tab">
				<div class="skill-time" style="font-weight: 400;font-size: 15px;">09:00</div>
				<div class="time-tip">即将开始</div>
			</div>
		</div>
		<div class="product" v-if="!data.list.length">
			<div class="item" v-if="data.styleType=='tiled'">
				<div class="img">
					<img :src="normalData.url">
					<img class="kill-icon" src="../../../../../assets/img/limit-kill.png"></img>
				</div>
				<div class="right">
					<div class="top">
						<div class="name">{{normalData.name}}</div>
					</div>
					<div class="sell-count">
						<div class="income-btn">
							<img class="income-icon" src="../../../../../assets/img/income-icon.png"></img>
							<div class="income-right">
								<div class="income-fu">
									<div>￥</div>
								</div>
								<div class="income-money">1</div>
							</div>
						</div>
					</div>
					<div class="price">
						<div class="left">
							&yen;99.99
							<div class="old">&yen;299.99</div>
						</div>
						<div class="btn">立即抢购</div>
					</div>
				</div>
			</div>
			<div style="display: flex;" v-else>
				<div class="item-row" v-for="(item,index) in [{},{},{}]" :key="index">
					<div class="img">
						<img :src="normalData.url">
						<!-- <div class="time">
							距结束：6天18:30:05
						</div> -->
						<img class="kill-icon" src="../../../../../assets/img/limit-kill.png"></img>
					</div>
					<div class="right">
						<div class="top">
							<div class="name">{{normalData.name}}</div>
						</div>
						<div class="price">
							<div class="left">
								&yen;99.99
								<div class="old">&yen;299.99</div>
							</div>
						</div>
						<div class="btn">立即抢购</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="product" v-else>
			<div class="item" v-for="(item, index) in data.list" :key="item.goodsId" v-if="index<18">
				<div class="img">
					<img :src="item.skuImgUrl">
					<div class="time" v-if="data.activityData.status === 0">
						距开始：<span v-if="data.activityData.days">{{data.activityData.days}}天</span>{{data.activityData.hours}}:{{data.activityData.minutes}}:{{data.activityData.seconds}}
					</div>
					<div class="time" v-if="data.activityData.status === 1">
						距结束：<span v-if="data.activityData.days">{{data.activityData.days}}天</span>{{data.activityData.hours}}:{{data.activityData.minutes}}:{{data.activityData.seconds}}
					</div>
					<div class="shouqing" v-if="item.stock <= 0">
						<img src="https://api.static.huibogoulive.cn/images/melyshop/shouqing.png" mode="widthFix" />
					</div>
				</div>
				<div class="right">
					<div class="sell-count">
						<div class="tit">剩余<span style="color: #ff547b;">{{item.stock}}</span>件</div>
					</div>
					<div class="price">
						<div class="left">
							&yen;{{item.secKillPrice}}
							<div class="old" v-if="item.originPrice">&yen;{{item.originPrice}}</div>
						</div>
						<div class="btn" v-if="data.activityData.status === 2" style="background: #999;color:#fff;border:none">已结束</div>
						<div class="btn" v-if="data.activityData.status === 1 && item.Stock>0">立即抢购</div>
						<div class="btn" v-if="data.activityData.status === 0 || (data.activityData.status === 1&&item.stock<=0)">去看看</div>
					</div>
				</div>
			</div>
		</div> -->

		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>

	</div>
</template>

<script>
	import moduleName from '../../moduleName';
	import controler from '../../controler';
	import edit from './edit';



	export default {
		name: 'normal-module',
		components: {
			moduleName,
			controler,
			edit,
		},
		props: {
			name: {
				type: String,
				default: ''
			},
			data: {
				type: Object,
				default: () => {
					return {}
				}
			},
		},
		data() {
			return {

				btnType: 1, //按钮三种样式
				normalData: {
					url: this.$store.state.pageManageConfig.staticImage.noPic,
					name: '此处是商品名称',
					point: '此处显示卖点',
					price: 99.00,
					marketPrice: 100.00,
					styleType:'tiled',
				}
			};
		},
		methods: {
			edit() {
				this.$emit('visibleChange', this.data.visible)
			},

			close() {
				this.$emit('visibleChange', false)
			}

		},
		mounted() {
			console.log(this.data, '秒杀')
		},
		watch: {

		}
	};
</script>
