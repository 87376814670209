<style scoped lang="less">
	.singleRow-module-edit {
		// position: absolute;
		// right: -530px;
		width: 480px;
		padding: 20px 20px 30px;
		background: #fff;
		font-size: 14px;

		.title {
			position: relative;
			font-size: 18px;
			padding-bottom: 20px;
			margin-bottom: 25px;
			border-bottom: 1px solid #ddd;
		}

		.add-btn {
			text-align: center;
			border: 1px dashed #ddd;
			font-size: 16px;
			color: #666;
			height: 48px;
			line-height: 48px;
			text-align: center;
			margin-bottom: 18px;
			cursor: pointer;
			width: 440px;
		}

		.product-list {
			padding-top: 10px;
			overflow: hidden;

			.product-item {
				position: relative;
				float: left;
				width: 65px;
				height: 65px;
				margin-right: 10px;
				margin-bottom: 10px;
			}

			.img {
				position: relative;
				width: 65px;
				height: 65px;
				overflow: hidden;

				img {
					display: block;
					width: 100%;
				}
			}

			.control {
				position: absolute;
				top: -10px;
				right: -10px;
			}
		}

		.link-info {
			width: 440px;
			height: 28px;
			line-height: 28px;
			background: #409eff;
			padding: 0 5px;
			margin-bottom: 10px;
			color: #fff;
			font-size: 14px;

			display: flex;

			.cont {
				flex: 1 1 auto;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.icon {
				flex: 0 0 auto;
			}
		}

		.mod-footer {
			margin-top: 50px;
			padding-top: 20px;
			border-top: 1px solid #ddd;

			display: flex;

			.left {
				flex: 1 1 auto;
				padding-top: 6px;
			}
		}
	}
</style>

<template>
	<!-- <transition name="scale"> -->
	<div class="singleRow-module-edit" v-if="visible">
		<p class="title">
			{{ name }}
			<!-- <Icon  @click="close" type="ios-close-empty" size=32 class="icon-close" color="#999"></Icon> -->
		</p>

		<!-- <div class="classify-list" v-if="data.activityData.Id">
			<div class="link-info">
				<div class="cont" @click="showActivityModal">{{data.activityData.Name}}</div>
				<div class="icon" @click="deleteData()">
					<i class="el-icon-close" style="color: #fff;font-size: 16px;"></i>
				</div>
			</div>
		</div> -->

		<!-- <div class="tit" v-if="data.activityData.Id" style="color: #666;padding: 20px 0 10px;">活动商品</div> -->
		<!-- <div v-if="data.list && data.list.length">
			<draggable class="product-list" :list="data.list" :options="{ animation: 300, handle: '.product-item' }">
				<transition-group name="bounce-out-left">
					<div class="product-item" :key="item.ProductId" v-for="item in data.list">
						<div class="img"><img :src="$store.state.pageManageConfig.imghost + item.ImgUrl" /></div>
						<div class="control" @click="deleteProduct(item)"><i class="el-icon-error" style="font-size: 24px;opacity: .6;"></i></div>
					</div>
				</transition-group>
			</draggable>
		</div> -->
		<!-- <div v-else>
			<div v-if="data.activityData.Id" style="text-align: center;color: #999;padding-bottom: 30px;">暂无商品</div>
		</div> -->

		<!-- <div class="add-btn" @click="showActivityModal" v-if="!data.activityData.Id">
			<i class="el-icon-circle-plus-outline" style="margin-right: 5px;vertical-align: -2px;font-size: 18px;"></i>
			选择秒杀活动
		</div>
		<div class="add-btn" @click="showShopModal" v-else>
			<i class="el-icon-circle-plus-outline" style="margin-right: 5px;vertical-align: -2px;font-size: 18px;"></i>
			选择商品
		</div> -->
		<div class="mod-footer">
			<div style="margin-right: 10px;">商品展示</div>
			<el-radio-group v-model="data.styleType">
				<el-radio style="width: 80px;" label="tiled">平铺</el-radio>
				<el-radio style="width: 80px;" label="slide">滑动</el-radio>
			</el-radio-group>
		</div>
		<div class="mod-footer">
			<div class="left">
				<el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></el-checkbox>
			</div>
		</div>

		<shop-modal :visible="shopVisible" :selectedList="data.list" :activityId="data.activityData.Id" @change="dataChange"
		 @cancel="shopVisible=false"></shop-modal>
		<activityModal :visible="activityVisible" :selected="data.activityData" @change="activityChange" @cancel="activityVisible = false"></activityModal>
	</div>
	<!-- </transition> -->
</template>

<script>
	import activityModal from '../../../../components/activityModal.vue';
	import shopModal from '../../../../components/activityShopListModal.vue';

	import {
		editSelectDiscountProduct
	} from '@/api/pageManage.js';
	import {
		seckillDetail,
	} from '@/api/goods'
	import draggable from 'vuedraggable';

	export default {
		name: 'normal-module-edit',
		components: {
			activityModal,
			shopModal,
			draggable
		},
		props: {
			data: {
				type: Object,
				default: () => {
					return {};
				}
			},
			visible: {
				type: Boolean,
				default: false
			},
			name: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				activityVisible: false,
				shopVisible: false,
				days: 0,
				hours: 0,
				minutes: 0,
				seconds: 0
			};
		},
		methods: {
			close() {
				this.$emit('close');
			},
			saveData() {
				this.$emit('submit');
			},
			showShopModal() {
				this.shopVisible = true;
			},
			showActivityModal() {
				this.activityVisible = true;
			},
			activityChange(record) {
				this.data.activityData = record[0];
				this.data.list = [];
				this.getShopList();
				this.getTime();
			},
			dataChange(record) {
				this.data.list = record.map(item => {
					item.price = +(item.ProductPrice * (item.Discount / 100)).toFixed(2);
					var discount = +(item.Discount / 10).toFixed(1);
					if (discount <= 0 || discount >= 10) {
						item.DiscountText = '秒杀' 
					} else {
						item.DiscountText = (+(item.Discount / 10).toFixed(1)) + '折'
					}
					return item;
				})
			},
			deleteData() {
				this.data.activityData = {};
				this.data.list = [];
			},
			deleteProduct(record) {
				this.data.list = this.data.list.filter(item => {
					return item.ProductId != record.ProductId;
				})
			},
			async getShopList(type) {
				const params = {
					secKillId: this.data.activityData.secKillId
				}

				const res = await seckillDetail(params);

				if (res.success) {
					
					var list = res.data.goodsList.map(item => {
						item.MinDiscountPrice = 0;
						item.MaxDiscountPrice = 0;
						item.price = 0;
						item.DiscountText="";

						return item;
					});
					
					if (type){
						var array = [];
						this.data.list.map(item=>{
							list.map(obj=>{
								if (obj.goodsId == item.goodsId){
									array.push(obj)
								}
							})
						})
						this.data.list = array;
					}else{
						this.data.list = list;
					}
					
				}
			},
			getTime() {
				var timeStr = 0;
				if (this.data.activityData.ActivityState === 0) {
					timeStr = this.data.activityData.StartTime;
				} else if (this.data.activityData.ActivityState === 1) {
					timeStr = this.data.activityData.EndTime;
				}
				if (timeStr) {
					var split = timeStr.split(' ');
					var year = +split[0].split('-')[0];
					var month = (+split[0].split('-')[1]) - 1;
					var day = +split[0].split('-')[2];
					var hour = +split[1].split(':')[0];
					var minute = +split[1].split(':')[1]
					var second = +split[1].split(':')[2]

					var time = 0;
					// if (this.data.activityData.ActivityState === 0){
					// 	time = new Date().getTime() - new Date(year, month, day, hour, minute, second).getTime();
					// }else{
					time = new Date(year, month, day, hour, minute, second).getTime() - new Date().getTime();
					// }

					var mss = time;
					var days = parseInt(mss / (1000 * 60 * 60 * 24));
					var hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
					var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
					var seconds = parseInt((mss % (1000 * 60)) / 1000);


					this.data.activityData.days = days;
					this.data.activityData.hours = hours < 10 ? ('0' + hours) : hours;
					this.data.activityData.minutes = minutes < 10 ? ('0' + minutes) : minutes;
					this.data.activityData.seconds = seconds < 10 ? ('0' + seconds) : seconds;

					console.log(this.data.activityData)
				}

			}
		},
		mounted() {
			console.log(this.data, '秒杀111')
			if (this.data.activityData.Id) {
				this.getTime();
				// this.data.list = this.data.list;
				this.getShopList('filter');
			}
		},
		watch: {
			data(value) {
				//this.$set(this.data,'btnType',1)
				//this.data.btnType = 1;
				//console.log(value)
			}
		}
	};
</script>
