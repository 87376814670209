<style scoped lang="less">
	.edit-wraper {
		flex: 1 1 auto;
		overflow: auto;
		margin-bottom: 70px;
	}

	::-webkit-scrollbar {
		width: 0px;
	}

	::-webkit-scrollbar-track {
		background-color: none;
	}

	::-webkit-scrollbar-thumb {
		background-color: none;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: none;
	}

	::-webkit-scrollbar-thumb:active {
		background-color: none;
	}
</style>

<template>
	<div class="edit-wraper">
		<div v-for="(item, index) in list" :key="index">
			<normal-module v-if="item.moduleType===1" :data="item.data" :name="item.name" :visible="item.data.editVisible"></normal-module>
			<edit-picture v-if="item.moduleType===9" :data="item.data" :name="item.name" :visible="item.data.editVisible"></edit-picture>
			<edit-shop v-if="item.moduleType===2" :data="item.data" :name="item.name" :visible="item.data.editVisible"></edit-shop>
			<edit-search v-if="item.moduleType===16" :data="item.data" :name="item.name" :visible="item.data.editVisible"></edit-search>
			<hot-area @move="(record, index)=>{handelCanMove(record, index, item.uid)}" v-if="item.moduleType===12" :data="item.data"
			:name="item.name" :visible="item.data.editVisible" :isTheme="isTheme"></hot-area>
			<point-shop v-if="item.moduleType===20" :data="item.data" :name="item.name" :visible="item.data.editVisible"></point-shop>
			<coupon-modal v-if="item.moduleType===18" :data="item.data" :name="item.name" :visible="item.data.editVisible"></coupon-modal>
			<notice-module v-if="item.moduleType===21" :data="item.data" :name="item.name" :visible="item.data.editVisible"></notice-module>
			<live-module v-if="item.moduleType===22" :data="item.data" :name="item.name" :visible="item.data.editVisible"></live-module>
			<guessYourLike v-if="item.moduleType===6" :data="item.data" :name="item.name" :visible="item.data.editVisible"></guessYourLike>
			<flash-sale v-if="item.moduleType===17" :data="item.data" :name="item.name" :visible="item.data.editVisible"></flash-sale>
			<new-product v-if="item.moduleType===23" :data="item.data" :name="item.name" :visible="item.data.editVisible"></new-product>
			<text-module v-if="item.moduleType===15" :data="item.data" :name="item.name" :visible="item.data.editVisible"></text-module>
			<wechat-group v-if="item.moduleType===24" :data="item.data" :name="item.name" :visible="item.data.editVisible"></wechat-group>
			<bind-group v-if="item.moduleType===25" :data="item.data" :name="item.name" :visible="item.data.editVisible"></bind-group>
			<banner-module v-if="item.moduleType===26" :data="item.data" :name="item.name" :visible="item.data.editVisible"></banner-module>
			<rich-text-module v-if="item.moduleType===27" :data="item.data" :name="item.name" :visible="item.data.editVisible"></rich-text-module>
		</div>

	</div>
</template>

<script>
	import normalModule from './browseModule/normalModule/edit';
	import editPicture from './browseModule/singleRowPicture/edit';
	import editShop from './browseModule/singleRowProduct/edit';
	import editSearch from './browseModule/searchModule/edit';
	import hotArea from './browseModule/hotArea/edit';
	import pointShop from './browseModule/pointShop/edit';
	import couponModal from './browseModule/couponModule/edit';
	import noticeModule from './browseModule/noticeModule/edit';
	import liveModule from './browseModule/liveModule/edit';
	import guessYourLike from './browseModule/guessYourLike/edit';
	import flashSale from './browseModule/flashSale/edit';
	import newProduct from './browseModule/newProduct/edit.vue';
	import textModule from './browseModule/textModule/edit.vue';
	import wechatGroup from './browseModule/wechatGroup/edit.vue';
	import bindGroup from './browseModule/bindGroup/edit.vue';
	import bannerModule from './browseModule/bannerModule/edit.vue';
	import richTextModule from './browseModule/richTextModule/edit.vue';
	export default {
		name: 'edit-box',
		components: {
			normalModule,
			editPicture,
			editShop,
			editSearch,
			hotArea,
			pointShop,
			couponModal,
			noticeModule,
			liveModule,
			guessYourLike,
			flashSale,
			newProduct,
			textModule,
			wechatGroup,
			bindGroup,
			bannerModule,
			richTextModule,
		},
		props: {
			list: {
				type: Array,
				default: () => {
					return []
				}
			},
			isTheme: {
				type: Boolean,
				default: false
			},
		},
		watch: {
			list() {
				// console.log(this.list)
			}
		},
		data() {
			return {
				dataList: []
			};
		},
		methods: {
			handelCanMove(record, index, uid) {
				this.$emit('move', record, index, uid);
			}
		},
		mounted() {
			// console.log(this.list)
		}
	};
</script>
