<style scoped lang="less">
    .singleRow-module-edit{
        // position: absolute;
        // right: -530px;
        width: 480px;
        padding: 20px 20px 30px;
        background: #fff;
        font-size: 14px;

        .title{
            position: relative;
            font-size: 18px;
            padding-bottom: 20px;
            margin-bottom: 25px;
            border-bottom: 1px solid #ddd;
        }

        .icon-close{
            position: absolute;
            right: 0;
            cursor: pointer;
        }

        .tip{
            margin-bottom: 20px;
        }

        .product-list{

            margin-bottom: 20px;

            .product-item{
                display: flex;
                margin-bottom: 10px;
				overflow: hidden;
            }
            .box{
                display: flex;
				align-items: center;
                flex: 1 1 auto;
                padding: 10px;
                background: #f5f5f5;
				overflow: hidden;

                .img{
                    position: relative;
                    width: 140px;
                    height: 70px;
                    overflow: hidden;
                    flex: 0 0 auto;
                    background: #fff;

                    img{
                        display: block;
                        height: 100%;
                    }
                }
                .info{
                    flex: 1 1 auto;
                    margin-left: 10px;
					
					display: flex;
					overflow: hidden;
					height: 28px;
					align-items: center;
					
					.txt{
						flex: 0 0 auto;
						font-size: 14px;
						height: 19px;
					}
					
					.link-info{
						flex: 1 1 auto;
						overflow: hidden;
						max-width: 100%;
						overflow: hidden;
						
						display: flex;
						align-items: center;
						
						height: 28px;
						color: #fff;
						
						font-size: 12px;
						padding: 0 5px;
					}
					
					.cont{
						padding: 0 5px;
						background: #409eff;
						height: 28px;
						line-height: 28px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.icon{
						padding-right:5px;
						background: #409eff;
						height: 28px;
						line-height: 32px;
					}
                }

            }
            .control{
                flex: 0 0 auto;
                width: 45px;
                text-align: right;
                margin-top: 20px;

                span{
                    display: inline-block;
                    cursor: pointer;
                }
                .edit{
                    color: #1890ff
                }
                .delete{
                    color: #f00000;
                    margin-top: 10px;
                }
            }
        }

        .tip1{
            text-align: center;
            color: #999;

            .count{
                color: #1890ff;
            }
        }

        .add-btn{
            width: 440px;
            text-align: center;
            border: 1px dashed #ddd;
            font-size: 16px;
            color: #666;
            height: 48px;
            line-height: 48px;
            text-align: center;
            margin-top: 10px;
            cursor: pointer;
        }

        .tip2{
            margin-top: 20px;
            margin-bottom: 10px;
            color: #999;
        }

        .mod-footer{
            margin-top: 30px;
            padding-top: 20px;
            border-top: 1px solid #ddd;

            display: flex;

            .left{
                flex: 1 1 auto;
                padding-top: 6px;
            }
        }

        .choose-box{
            display: flex;
            width: 225px;
        }

        .route{
            line-height: 24px;
            flex: 1 1 auto;
            font-size: 12px;
            color: #666;
            font-family: simsun;
            margin-left: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    
</style>

<template>
    <!-- <transition name="scale"> -->
        <div class="singleRow-module-edit" v-if="visible">
            
            <p class="title">
                {{name}} 
                <!-- <i @click="close" class="el-icon-close icon-close" style="font-size: 32px;color: #999;top:-12px"></i> -->
            </p>

            <div class="tip">添加图片</div>

            <draggable class="product-list" :list="data.list" :options="{animation: 300, handle:'.product-item'}">
                <transition-group name="bounce-out-left">
                    <div class="product-item" :key="item.uid" v-for="item in data.list">
                        <div class="box">
                            <div class="img">
                                <img :src="$store.state.pageManageConfig.imghost+item.imgUrl" />
                            </div>
                            <div class="info">
								<div class="txt">链接：</div>
								<div class="link-info" v-if="!item.selectedData.data" @click="showModal(item)">
									<div class="cont" style="background: transparent;color: #409eff;">请选择页面</div>
									<div class="icon" style="background: transparent;">
										<i class="el-icon-arrow-down" style="color: #409eff;font-size: 16px;background: transparent;"></i>
									</div>
								</div>
								<div class="link-info" v-else>
									<div class="cont" @click="showModal(item)">{{item.selectedData.text}}</div>
									<div class="icon" @click="deleteLinkData(item)">
										<i class="el-icon-close" style="color: #fff;font-size: 16px;"></i>
									</div>
									<div class="icon">
										<i class="el-icon-arrow-down" style="color: #fff;font-size: 16px;"></i>
									</div>
								</div>
                            </div>
                        </div>
                        <div class="control">
                           <el-upload
                               ref="upload"
                           	:show-file-list="false"
                               accept="image/jpg, image/png, image/jpeg, image/gif"
                               :on-success="(data, file)=>handleSuccess(data, file, item)"
                               :on-error="handleFormatError"
                               :action="$store.state.pageManageConfig.uploadUrl"
                               >
                                <span class="edit">修改</span>
                            </el-upload>
                            <span class="delete" @click="deleteData(item)">删除</span>
                        </div>
                    </div>
                </transition-group>
            </draggable>

            
            <div class="tip1">您已添加<span class="count">{{data.list.length}}</span>张图片</div>

            <el-upload
                ref="upload"
				:show-file-list="false"
                accept="image/jpg, image/png, image/jpeg, image/gif"
                :on-success="(data, file)=>handleSuccess(data, file, null)"
                :on-error="handleFormatError"
                :action="$store.state.pageManageConfig.uploadUrl"
                >
                <div class="add-btn">
					<i class="el-icon-plus" style="margin-right: 5px;vertical-align: -2px;font-size: 18px;"></i>添加图片
                </div>
            </el-upload>

            <div class="tip2">建议图片宽度750，高200~950，支持类型：jpg、png。</div>
			
			<div class="mod-footer">
			    <div class="left">
			        <el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></el-checkbox>
			    </div>
			    <!-- <div class="right">
			        <Button type="primary" @click="saveData">保存</Button>
			    </div> -->
			</div>

            <link-modal :visible="shopVisible" :showLiveTab="true" @change="dataChange" @cancel="shopVisible=false"></link-modal>

        </div>
    <!-- </transition> -->
</template>

<script>

import linkModal from '@/views/components/linkModal';
import draggable from 'vuedraggable';

export default {
    name: 'normal-module-edit',
    components: {
        linkModal,
        draggable
    },
    props: {
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        visible: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            shopVisible: false,
            editData: {}
        };
    },
    methods: {
        close(){
            this.$emit('close')
        },
        showModal(record){
            this.editData = record;
            this.shopVisible = true;
        },
        dataChange(record){
			console.log(record)
            this.data.list.map(item=>{
                if (item.uid == this.editData.uid){
                    item.selectedData = record;
                }
                return item;
            })
            console.log(this.data)
            
        },

        //添加产品，并且过滤掉相同产品
        addData(record){
            const list = this.data.list || [];
            record.map(item =>{
                let like = false;
                list.map(item1=>{
                    if (item1.Id === item.Id){
                        like = true;
                    }
                })

                if (!like){
                    list.push(item);
                }

            })

            this.data.list.concat(list);
        },

        //修改产品， 覆盖掉需替换的产品
        changeRecordData(record){

            this.data.list.map(item=>{
                if (item.Id === this.editData.Id){
                    for (var i in record){
                        item[i] = record[i]
                    }
                }
                return item;
            })

        },
        deleteData(record){

            this.data.list = this.data.list.filter(item=>{
                return item.uid !== record.uid;
            })

        },
		deleteLinkData(record){
			this.data.list.map(item=>{
			    if (item.uid == record.uid){
			        item.selectedData = {};
			    }
			    return item;
			})
		},
        changeData(record){

            this.shopVisible = true;
            this.isSingle = true;

            this.editData = record;

        },
        handleMaxSize(){
            this.$Message.error('上传图片最大2M！');
        },
        handleFormatError(){
            this.$Message.error('上传失败！');
        },
        handleSuccess(data, file, record){
		
			var that = this;
			var img = new Image();
			img.onload = function(){
				that.setImage(data, img.width, img.height, record)
			}
			img.src = this.$store.state.pageManageConfig.imghost + data[0];
			
        },
		setImage(data, width, height, record){
			if (record){
			    this.data.list.map(item=>{
			        if (item.uid == record.uid){
			            item.imgUrl = data[0];
						item.width = width;
						item.height = height;
			        }
			        return item;
			    })
			}else{
			    this.data.list.push({
			        uid: this.guid(),
			        imgUrl: data[0],
					width: width,
					height: height,
			        type: 1, //链接类型
			        link: '', //自定义链接
			        selectedData: {}, //弹框选中数据
			    })
			}
		},
        saveData(){
            this.$emit('submit')
        },
        guid(){
            var a=function(){return(65536*(1+Math.random())|0).toString(16).substring(1)};
            return a()+a()+"-"+a()+"-"+a()+"-"+a()+"-"+a()+a()+a()
        }

    },
    mounted () {
        // this.data.list.push({
        //         uid: this.guid(),
        //         imgUrl: '/ShopDecorate/61f677cf-ce0d-4364-b3d8-9cdeffe089aa.jpg',
        //         type: 1, //链接类型
        //         link: '', //自定义链接
        //         selectedData: {}, //弹框选中数据
        //     })
		// console.log(this.data)
    },
    watch: {
        data(value){
            //this.$set(this.data,'btnType',1)
            
            //this.data.btnType = 1;
            //console.log(value)
        }
    }
};
</script>

