<style lang="less">
	.normal-module-edit {
		// position: absolute;
		// right: -530px;
		width: 480px;
		padding: 20px 20px 30px;
		background: #fff;

		.title {
			position: relative;
			font-size: 18px;
			padding-bottom: 20px;
			margin-bottom: 25px;
			border-bottom: 1px solid #ddd;
		}

		.icon-close {
			position: absolute;
			right: 0;
			top: -12px;
			cursor: pointer;
		}

		.tips {
			margin-right: 10px;
			font-size: 12px;
			color: #999;
		}

		.tips1 {
			margin-left: 10px;
			font-size: 12px;
			color: #999;
		}
		.avatar-uploader .el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}
		
		.avatar-uploader .el-upload:hover {
			border-color: #409EFF;
		}
		
		.avatar-uploader-icon {
			font-size: 28px;
			color: #8c939d;
			width: 140px;
			height: 140px;
			line-height: 140px;
			text-align: center;
		}
		
		.avatar {
			width: 140px;
			height: 140px;
			display: block;
		}
	}
</style>

<template>
	<!-- <transition name="scale"> -->
	<div class="normal-module-edit" v-if="visible">

		<p class="title">
			{{name}}
			<!-- <i @click="close" class="el-icon-close icon-close" style="font-size: 32px;color: #999;"></i> -->
		</p>

		<el-form :model="data" label-width="80px">
			<el-form-item label="页面标题:" prop="title">
				<el-input v-model="data.title" style="width: 270px;"></el-input>
			</el-form-item>
			<el-form-item label="内容样式:" prop="pageBg">
				<div class="flex" style="align-items: center;">
					<div style="margin-right: 20px;">页面背景:</div>
					<!-- <div style="margin-right: 30px;margin-left: 10px;border: 1px solid #ddd;" :style="{width: '100px',height: '30px',background:data.pageBg,}"></div> -->
					<el-color-picker v-model="data.pageBg"></el-color-picker>
				</div>
			</el-form-item>
			<el-form-item label="顶部样式:" prop="titleBg">
				<div class="flex" style="align-items: center;">
					<div style="margin-right: 20px;">导航背景:</div>
					<!-- <div style="margin-right: 30px;margin-left: 10px;border: 1px solid #ddd;" :style="{width: '100px',height: '30px',background:data.titleBg,}"></div> -->
					<el-color-picker v-model="data.titleBg"></el-color-picker>
				</div>
			</el-form-item>
            <el-form-item label="是否分享:">
				<el-switch v-model="data.isShare"> </el-switch>
			</el-form-item>
            <div v-if='data.isShare'>
                <el-form-item label="分享内容:"></el-form-item>
                <div class="flex">
                    <div style="font-size: 14px;margin-right: 35px;margin-left: 20px;">分享图片:</div>
                    <el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false"
                    :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <img v-if="shareImage" :src="shareImage" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
                <div class="flex" style="margin-top: 20px;">
                    <div style="font-size: 14px;margin-right: 10px;margin-left: 20px;">页面分享描述:</div>
                    <div style="width: 300px;">
                        <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="data.shareContent">
                        </el-input>
                    </div>
                </div>
                <div style="font-size: 14px;text-align: center;margin-top: 20px;color: #999;">不单独设置页面分享图片、描述时使用商城分享设置的分享描述</div>
                <div class="mod-footer" style="margin-top: 30px;">
                    <div class="flex">
                        <div style="font-size: 14px;margin-right: 35px;margin-left: 20px;">海报图片:</div>
                        <el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false"
                        :on-success="handleAvatarSuccess2" :before-upload="beforeAvatarUpload2">
                            <img v-if="posterImage" :src="posterImage" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </div>
                    <div style="font-size: 14px;margin-top: 20px;color: #999;padding-left: 130px;">图片最大不超过10M</div>
                    <div class="left" style="margin-top: 20px;">
                        <el-checkbox v-model="data.showShare"><span style="font-size: 14px;color:#666">是否显示下载海报入口</span></el-checkbox>
                    </div>
                </div>
            </div>
			
		</el-form>

	</div>
	<!-- </transition> -->
</template>

<script>
	import config from '@/config/index'
	export default {
		name: 'normal-module-edit',
		components: {},
		props: {
			data: {
				type: Object,
				default: () => {
					return {}
				}
			},
			visible: {
				type: Boolean,
				default: false
			},
			name: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				obj: this.data,
				imgApi: config.UPLOAD_IMG,
				shareImage:this.data.shareImage,
				posterImage:this.data.posterImage,
                
			};
		},
        mounted(){
            
        },
		methods: {
			close() {
				this.$emit('close')
			},
			// 上传规格图成功钩子
			handleAvatarSuccess(res) {
				this.shareImage = res.data[0];
				this.data.shareImage = res.data[0];
				console.log(this.data, res);
				return true;
			},
			// 上传规格图前钩子
			beforeAvatarUpload(file) {
				const isLt3M = file.size / 1024 / 1024 < 3;
				if (!isLt3M) {
					this.$message.error('图片大小请控制在3M以内!');
				}
				return isLt3M;
			},
			// 上传规格图成功钩子
			handleAvatarSuccess2(res) {
				this.posterImage = res.data[0];
				var img = res.data[0]+'?x-oss-process=image/resize,w_750';
				this.data.posterImage = img;
				console.log("-----img---",img);
				console.log(this.data, res);
				return true;
			},
			// 上传规格图前钩子
			beforeAvatarUpload2(file) {
				const isLt3M = file.size / 1024 / 1024 < 10;
				if (!isLt3M) {
					this.$message.error('图片大小请控制在10M以内!');
				}
				return isLt3M;
			},
		},
		mounted() {
			// console.log(this.obj)
		},
		watch: {
			data(value) {
                console.log('value',value);
				this.obj = value;
			},
		}
	};
</script>
