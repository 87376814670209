<style scoped lang="less">
	.menu-box {
		width: 250px;
		height: 100%;
		padding: 45px 25px;
		overflow: auto;
		background: #fff;
		flex: 0 0 auto;
		margin-right: 50px;

		.menu-title {
			font-size: 20px;
		}

		.item-box {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			list-style: none;
			margin-top: 30px;

			.item {
				width: 85px;
				border: 2px solid #ddd;
				margin-bottom: 40px;
				cursor: move;

				.img {
					width: 81px;
					height: 50px;
					overflow: hidden;

					img {
						display: block;
						width: 100%;
						pointer-events: none;
					}
				}

				.name {
					font-size: 14px;
					color: #666;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					text-align: center;
					margin-bottom: 10px;
				}
			}
			
			.item15{
				.img{
					img{
						width: 60%;
						margin: 5px auto 0;
					}
				}
			}
		}
	}
	
	::-webkit-scrollbar {
	width: 0px;
	}
	
	::-webkit-scrollbar-track {
	background-color: none;
	}
	
	::-webkit-scrollbar-thumb {
	background-color: none;
	}
	
	::-webkit-scrollbar-thumb:hover {
	background-color: none;
	}
	
	::-webkit-scrollbar-thumb:active {
	background-color: none;
	}
</style>

<template>
	<div class="menu-box">
		<div class="menu-item">
			<!-- <p class="menu-title">{{item.title}}</p> -->

			<div class="menu-list">
				<!-- <draggable class="item-box" :move="getdata" :end="end" @update="datadragEnd" :options="{animation: 300, handle:'.item', group: 'page'}"> -->
				<div class="item-box">
					<div class="item" :class="{'item15':record.moduleType==15}" @click="addModule(record)" v-for="(record, index) in menuList" :key="index" v-if=" (!isGroup && record.moduleType !== 25) || (isGroup && (record.moduleType == 25 && IsOpenCommunity) || record.moduleType !== 25) ">
						<div class="img">
							<img :src="record.icon" />
						</div>
						<p class="name">{{record.name}}</p>
					</div>
					<!--  </draggable> -->
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import draggable from 'vuedraggable';
	
	import {
		mapState
	} from 'vuex';

	export default {
		name: 'page-manage',
		components: {
			draggable
		},
		data() {
			return {
				menuList: [],
				isGroup: this.$route.query.isGroup === 'group'
			};
		},
		computed: {
			...mapState({
				IsOpenCommunity: (state)=>{
					return state.user.IsOpenCommunity
				}
			})
		},
		watch:{
			IsOpenCommunity(){
				this.setMenu();
			}
		},
		methods: {
			getdata: function(evt) {
				console.log(evt)
				//return evt.target.className !== 'item-box';
			},
			end(event) {
				console.log(event);
			},
			datadragEnd: function(evt) {
				console.log('拖动前的索引：' + evt.oldIndex);
				console.log('拖动后的索引：' + evt.newIndex);
			},
			addModule(record) {
				this.$emit('click', record)
			},
			setMenu(){
				var list = this.$store.state.pageManageConfig.moduleList;
				if (this.isGroup && !this.IsOpenCommunity){
					list = list.filter(item=>{
						return item.moduleType != 24 && item.moduleType != 25;
					})
				}else if (!this.isGroup){
					if (!this.IsOpenCommunity){
						list = list.filter(item=>{
							return item.moduleType != 24 && item.moduleType != 25;
						})
					}else{
						list = list.filter(item=>{
							return item.moduleType != 25;
						})
					}
				}
				
				this.menuList = list;
				console.log(this.menuList)
			}
		},
		mounted() {
			this.setMenu();
			
			// var el = this.$refs.dragable.$children[1].$el.children[1];
			// let vm = this;
			// Sortable.create(el, {
			//     onStart: vm.startFunc,
			//     onEnd: vm.endFunc,
			//     onChoose: vm.chooseFunc
			// });
		},
		watch: {}
	};
</script>
