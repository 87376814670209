<style scoped lang="less">
    .singleRow-module-edit{
        // position: absolute;
        // right: -530px;
        width: 480px;
        padding: 20px 20px 30px;
        background: #fff;
        font-size: 14px;

        .title{
            position: relative;
            font-size: 18px;
            padding-bottom: 20px;
            margin-bottom: 25px;
            border-bottom: 1px solid #ddd;
        }

        .icon-close{
            position: absolute;
            right: 0;
            cursor: pointer;
        }

        .message{
            font-size: 16px;
			color: #999;
			text-align: center;
        }

    }
	
	.mod-footer{
	    margin-top: 30px;
	    padding-top: 20px;
	    border-top: 1px solid #ddd;
	
	    display: flex;
	
	    .left{
	        flex: 1 1 auto;
	        padding-top: 6px;
	    }
	}

    
</style>

<template>
    <!-- <transition name="scale"> -->
        <div class="singleRow-module-edit" v-if="visible">
            
            <p class="title">
                {{name}} 
                <!-- <Icon  @click="close" type="ios-close-empty" size=32 class="icon-close" color="#999"></Icon> -->
            </p>
			
			<img style="display: block;width: 200px;margin:0 auto 40px;" src="https://api.static.huibogoulive.cn/images/melyshop/bind-group-1.jpg?v=1"/>

            <p class="message">该模块的数据，系统自动关联员工管理的企业微信群</p>
			
			<div class="mod-footer">
			    <div class="left">
			        <el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></el-checkbox>
			    </div>
			    <!-- <div class="right">
			        <Button type="primary" @click="saveData">保存</Button>
			    </div> -->
			</div>

        </div>
    <!-- </transition> -->
</template>

<script>


export default {
    name: 'normal-module-edit',
    components: {
    },
    props: {
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        visible: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
        };
    },
    methods: {
        close(){
            this.$emit('close')
        },

    },
    mounted () {

    },
    watch: {
    }
};
</script>

