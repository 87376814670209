<style scoped lang="less">
    .singleRow-module-edit{
        position: absolute;
        right: -530px;
        width: 480px;
        padding: 20px 20px 30px;
        background: #fff;
        font-size: 14px;

        .title{
            position: relative;
            font-size: 18px;
            padding-bottom: 20px;
            margin-bottom: 25px;
            border-bottom: 1px solid #ddd;
        }

        .icon-close{
            position: absolute;
            right: 0;
            cursor: pointer;
        }

        .tip{
            margin-bottom: 15px;
        }

        .product-list{
            .product-item{
                display: flex;
                margin-bottom: 20px;
            }
            .box{
                display: flex;
                flex: 1 1 auto;
                background: #f5f5f5;

                .img{
                    position: relative;
                    width: 90px;
                    height: 90px;
                    overflow: hidden;
                    flex: 0 0 auto;

                    img{
                        display: block;
                        width: 100%;
                    }
                    .change{
                        position: absolute;
                        width: 100%;
                        left:0;
                        bottom: 0;
                        height: 26px;
                        line-height: 26px;
                        text-align: center;
                        color: #fff;
                        background: rgba(0,0,0,.5);
                    }
                }
                .info{
                    flex: 1 1 auto;
                    margin-left: 10px;
                    margin-right: 20px;

                    .name{
                        height: 36px;
                        line-height: 18px;
                        margin: 10px 0;
                        color: #666;
                    }

                    .price{
                        font-size: 18px;

                        .old{
                            font-size: 14px;
                            color: #ccc;
                            text-decoration: line-throuth;
                        }
                    }
                }

            }
            .control{
                flex: 0 0 auto;
                width: 45px;
                text-align: right;
                margin-top: 20px;

                span{
                    display: inline-block;
                    cursor: pointer;
                }
                .edit{
                    color: #1890ff
                }
                .delete{
                    color: #f00000;
                    margin-top: 10px;
                }
            }
        }

        .tip1{
            text-align: center;
            color: #999;

            .count{
                color: #1890ff;
            }
        }

        .add-btn{
            width: 440px;
            text-align: center;
            border: 1px dashed #ddd;
            font-size: 16px;
            color: #666;
            height: 48px;
            line-height: 48px;
            text-align: center;
            margin-top: 10px;
            cursor: pointer;
        }

        .mod-footer{
            margin-top: 50px;
            padding-top: 20px;
            border-top: 1px solid #ddd;

            display: flex;

            .left{
                flex: 1 1 auto;
                padding-top: 6px;
            }
        }

        .tip2{
            color: #999;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .video-box{
            width: 440px;
        }

        .video-content{
            position: relative;
            display: flex;

            .left{
                flex: 1 1 auto;

                .cont{
                    position: relative;
                    width: 200px;

                    img{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                    .text{
                        position: absolute;
                        left:0;
                        bottom:0;
                        width: 100%;
                        height: 30px;
                        line-height: 30px;
                        color: #fff;
                        text-align: center;
                        background: rgba(0,0,0,.5);
                    }
                }
            }
            .control{
                flex: 0 0 auto;
                width: 45px;
                text-align: right;
                margin-top: 20px;

                span{
                    display: inline-block;
                    cursor: pointer;
                }
                .edit{
                    color: #1890ff
                }
                .delete{
                    color: #f00000;
                    margin-top: 10px;
                }
            }
        }

        .progress-box{
           margin-bottom: 10px;
        }


    }

    
</style>

<template>
    <transition name="scale">
        <div class="singleRow-module-edit" v-if="visible">
            
            <p class="title">
                {{name}} 
                <Icon  @click="close" type="ios-close-empty" size=32 class="icon-close" color="#999"></Icon>
            </p>

            <p class="tip">选择视频</p>

            <div class="progress-box"  v-if="isProgress">
                <Progress :percent="Math.floor(percent)"></Progress>
            </div>

            <div class="video-content" v-if="data.videoUrl!==''">
                <div class="left">
                    <Upload 
                        :show-upload-list="false"
                        :on-success="handleVideoImageSuccess"
                        :format="['jpg','jpeg','png']"
                        :on-format-error="handleVideoImageFormatError"
                        :on-exceeded-size="handleVideoImageMaxSize"
                        :action="$store.state.pageManageConfig.uploadUrl"
                        :on-progress="progress"
                        style="display:block">

                        <div class="cont">
                            <video width="200px" style="vertical-align:top" :src="$store.state.pageManageConfig.imghost+data.videoUrl">
                            您的浏览器不支持 video 标签。
                            </video>
                            <img v-if="data.coverImage!==''" :src="$store.state.pageManageConfig.imghost+data.coverImage" />
                            <p class="text">更改图片</p>
                        </div>

                    </Upload>

                    
                </div>
                <div class="control">
                    <Upload
                     class="edit"
                    :show-upload-list="false"
                    :on-success="handleVideoSuccess"
                    :format="['mp4','webM','ogg ']"
                    :on-format-error="handleVideoFormatError"
                    :on-exceeded-size="handleVideoMaxSize"
                    :action="$store.state.pageManageConfig.uploadUrl"
                    :on-progress="progress"
                    style="display:block">
                        <span>修改</span>
                    </Upload>
                    <span class="delete" @click="data.coverImage='';data.videoUrl='';">删除</span>
                </div>
            </div>

            <Upload
                 v-else
                :show-upload-list="false"
                :on-success="handleVideoSuccess"
                :format="['mp4','webM','ogg ']"
                :on-format-error="handleVideoFormatError"
                :on-exceeded-size="handleVideoMaxSize"
                :action="$store.state.pageManageConfig.uploadUrl"
                :on-progress="progress"
                style="display:block">
                <div class="add-btn">
                    <Icon type="ios-plus-outline" style="margin-right: 5px;vertical-align: -2px;" size=18></Icon>添加视频
                </div>
            </Upload>
            <p class="tip2">建议视频分辨率720以上</p>

            <p style="font-size: 12px;margin-bottom: 5px">视频标题</p>
            <Input style="margin-bottom: 20px;" type="text" v-model="data.videoTitle" :maxlength="20" placeholder="请填写视频标题"/>

            <p style="font-size: 12px;margin-bottom: 5px">视频描述</p>
            <Input style="margin-bottom: 40px;" type="textarea" v-model="data.videoDesc" :maxlength="200" placeholder="请填写视频描述"/>

            <p class="tip">关联商品</p>

            
            <draggable class="product-list" :list="data.list" :options="{animation: 300, handle:'.product-item'}">
                <transition-group name="bounce-out-left">
                    <div class="product-item" :key="item.Id" v-for="item in data.list">
                        <div class="box">
                            <div class="img">
                                <Upload
                                    ref="upload"
                                    :show-upload-list="false"
                                    :on-success="(data)=>{handleSuccess(data, item)}"
                                    :format="['jpg','jpeg','png']"
                                    :max-size="2048"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :action="$store.state.pageManageConfig.uploadUrl"
                                    style="">
                                    <img :src="$store.state.pageManageConfig.imghost+item.ImgUrl" />
                                    <p class="change">更改图片</p>
                                </Upload>
                            </div>
                            <div class="info">
                                <p class="name">{{item.Name}}</p>
                                <div class="price">￥{{item.Price}}<span class="old">￥{{item.MarketPrice}}</span></div>
                            </div>
                        </div>
                        <div class="control">
                            <span class="edit" @click="changeData(item)">修改</span>
                            <span class="delete" @click="deleteData(item)">删除</span>
                        </div>
                    </div>
                </transition-group>
            </draggable>
            

            <p class="tip1">您已添加<span class="count">{{data.list.length}}</span>个商品</p>

            <div class="add-btn" @click="showModal">
                <Icon type="ios-plus-outline" style="margin-right: 5px;vertical-align: -2px;" size=18></Icon>添加商品
            </div>

            <div class="mod-footer">
                <div class="left">
                    <Checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></Checkbox>
                </div>
                <div class="right">
                    <Button type="primary" @click="saveData">保存</Button>
                </div>
            </div>

            <shop-modal :visible="shopVisible" :disableList="data.list" :isSingle="isSingle" @change="dataChange" @cancel="shopVisible=false"></shop-modal>

        </div>
    </transition>
</template>

<script>

import shopModal from '@/views/components/shopListModal';

import draggable from 'vuedraggable';

export default {
    name: 'normal-module-edit',
    components: {
        shopModal,
        draggable
    },
    props: {
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        visible: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            shopVisible: false,
            isSingle: false, //是否是单选
            editData: {},
            isProgress: false,
            percent: 0,
        };
    },
    methods: {
        close(){
            this.$emit('close')
        },
        showModal(){
            this.shopVisible = true;
            this.isSingle = false;
        },
        dataChange(record){

            if (!this.isSingle){ //添加
                this.addData(record);
            }else{ //修改
                this.changeRecordData(record);
            }
            
        },

        //添加产品，并且过滤掉相同产品
        addData(record){
            const list = this.data.list || [];
            record.map(item =>{
                let like = false;
                list.map(item1=>{
                    if (item1.Id === item.Id){
                        like = true;
                    }
                })

                if (!like){
                    list.push(item);
                }

            })

            this.data.list.concat(list);
        },

        //修改产品， 覆盖掉需替换的产品
        changeRecordData(record){

            this.data.list.map(item=>{
                if (item.Id === this.editData.Id){
                    for (var i in record){
                        item[i] = record[i]
                    }
                }
                return item;
            })

        },
        deleteData(record){

            this.data.list = this.data.list.filter(item=>{
                return item.Id !== record.Id;
            })

        },
        changeData(record){

            this.shopVisible = true;
            this.isSingle = true;

            this.editData = record;

        },

        /***上传图片****/
        handleMaxSize(){
            this.$Message.error('上传图片最大2M！');
        },
        handleFormatError(){
            this.$Message.error('图片格式错误！');
        },
        handleSuccess(data, record){
            this.data.list.map(item=>{
                if (item.Id === record.Id){
                    item.ImgUrl = data[0];
                    item.isSelfUpload = true;
                }
                return item;
            })
        },

        /**上传视频*********/
        handleVideoMaxSize(){
            //this.$Message.error('上传图片最大2M！');
        },
        handleVideoFormatError(){
            this.$Message.error('视频只支持webM、mp4、ogg三种格式');
        },
        handleVideoSuccess(data){
            this.data.videoUrl = data[0];
        },

        /**上传视频图片*********/
        handleVideoImageMaxSize(){
            //this.$Message.error('上传图片最大2M！');
        },
        handleVideoImageFormatError(){
            this.$Message.error('图片格式错误！');
        },
        handleVideoImageSuccess(data){
            this.data.coverImage = data[0];
        },

        //上传进度条
        progress(event, file, fileList){
            this.isProgress = event.percent !== 100;
            this.percent = event.percent;
            console.log(event)
        },


        saveData(){
            this.$emit('submit')
        }

    },
    mounted () {

    },
    watch: {
        data(value){
            //this.$set(this.data,'btnType',1)
            
            //this.data.btnType = 1;
            //console.log(value)
        }
    }
};
</script>

