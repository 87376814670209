<style scoped lang="less">
    .singleRow-module-edit{
        // position: absolute;
        // right: -530px;
        width: 480px;
        padding: 20px 20px 30px;
        background: #fff;
        font-size: 14px;

        .title{
            position: relative;
            font-size: 18px;
            padding-bottom: 20px;
            margin-bottom: 25px;
            border-bottom: 1px solid #ddd;
        }

        .icon-close{
            position: absolute;
            right: 0;
            cursor: pointer;
        }

        .tip{
            margin-bottom: 10px;
        }

        .product-list{
            .product-item{
                display: flex;
                margin-bottom: 10px;
            }
            .box{
                flex: 1 1 auto;
                background: #f5f5f5;
                height: 60px;
				padding: 0 10px;
				font-size: 14px;
				color: #666;
				
				display: flex;
				align-items: center;
				
				img{
					flex: 0 0 auto;
					display: block;
					width: 54px;
					height: 32px;
					margin-right: 10px;
				}
				
				.info{
					flex: 1 1 auto;
				}
            }
            .control{
                flex: 0 0 auto;
                width: 45px;
                text-align: right;
                margin-top: 20px;

                span{
                    display: inline-block;
                    cursor: pointer;
                }
                .edit{
                    color: #1890ff
                }
                .delete{
                    color: #f00000;
                    margin-top: 3px;
                }
            }
        }

       

        .add-btn{
            width: 440px;
            text-align: center;
            border: 1px dashed #ddd;
            font-size: 16px;
            color: #666;
            height: 48px;
            line-height: 48px;
            text-align: center;
            margin-top: 10px;
            cursor: pointer;
        }

        .mod-footer{
            margin-top: 30px;
            padding-top: 20px;
            border-top: 1px solid #ddd;

            display: flex;

            .left{
                flex: 1 1 auto;
                padding-top: 6px;
            }
        }

        .choose-box{
            display: flex;
            width: 225px;
        }

        .route{
            line-height: 24px;
            flex: 1 1 auto;
            font-size: 12px;
            color: #666;
            font-family: simsun;
            margin-left: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    
</style>

<template>
    <!-- <transition name="scale"> -->
        <div class="singleRow-module-edit" v-if="visible">
            
            <p class="title">
                {{name}} 
                <!-- <i @click="close" class="el-icon-close icon-close" style="font-size: 32px;color: #999;top:-12px"></i> -->
            </p>
			
			<div style="color: #909399;margin-bottom: 20px;">商城内优先根据开播状态排序：直播中＞预告＞已结束；若开播状态相同，开播时间越早，排序越靠前</div>
			
			<!-- <draggable class="product-list" :list="data.list" :options="{animation: 300, handle:'.product-item'}"> -->
			<div class="product-list">
			    <transition-group name="bounce-out-left">
			        <div class="product-item" :key="item.Id" v-for="item in data.list">
			            <div class="box">
							<img :src="item.AnchorImgUrl" />
							<div class="info">
								<div>{{item.RoomName}}</div>
								<div style="margin-top: 5px;">{{item.StartTime}}至{{item.EndTime}}</div>
							</div>
			            </div>
			            <div class="control">
			                <!-- <span class="edit" @click="changeData(item)">修改</span> -->
			                <span class="delete" @click="deleteData(item)">删除</span>
			            </div>
			        </div>
			    </transition-group>
			<!-- </draggable> -->
			</div>

			<div class="add-btn" @click="showModal">
				<i class="el-icon-plus" style="margin-right: 5px;vertical-align: -2px;font-size: 18px;"></i>添加直播
			</div>
			
            <div class="mod-footer">
                <div class="left">
                    <el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></el-checkbox>
                </div>
                <!-- <div class="right">
                    <Button type="primary" @click="saveData">保存</Button>
                </div> -->
            </div>

            <live-modal :visible="shopVisible" :selectedList="data.list" @change="dataChange" @cancel="shopVisible=false"></live-modal>

        </div>
    <!-- </transition> -->
</template>

<script>

import liveModal from '@/views/components/liveModal';
import draggable from 'vuedraggable';

export default {
    name: 'normal-module-edit',
    components: {
        liveModal,
        draggable
    },
    props: {
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        visible: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            shopVisible: false,
            editData: {}
        };
    },
    methods: {
        close(){
            this.$emit('close')
        },
        showModal(record){
            this.editData = record;
            this.shopVisible = true;
        },
        dataChange(record){

            this.addData(record);
            
        },

        //添加产品，并且过滤掉相同产品
        addData(record){
			this.data.list = record;
        },

        deleteData(record){

            this.data.list = this.data.list.filter(item=>{
                return item.Id !== record.Id;
            })

        },
        changeData(record){

            this.shopVisible = true;
            this.isSingle = true;

            this.editData = record;

        },
        saveData(){
            this.$emit('submit')
        },
        guid(){
            var a=function(){return(65536*(1+Math.random())|0).toString(16).substring(1)};
            return a()+a()+"-"+a()+"-"+a()+"-"+a()+"-"+a()+a()+a()
        }

    },
    mounted () {
        // this.data.list.push({
        //         uid: this.guid(),
        //         imgUrl: '/ShopDecorate/61f677cf-ce0d-4364-b3d8-9cdeffe089aa.jpg',
        //         type: 1, //链接类型
        //         link: '', //自定义链接
        //         selectedData: {}, //弹框选中数据
        //     })
    },
    watch: {
        data(value){
            //this.$set(this.data,'btnType',1)
            
            //this.data.btnType = 1;
            //console.log(value)
        }
    }
};
</script>

