<style scoped lang="less">
	.singleRowProduct-module {

		.title {
			padding: 10px;
			font-size: 12px;
			background: #fff;
			word-break: break-all;
			line-height: 18px;
		}

	}

	.haveSpace {
		margin-bottom: 10px;
	}
</style>

<template>
	<div class="singleRowProduct-module" @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;"
	:class="{haveSpace: !data.hideSpace}">
		<module-name :name="name"></module-name>
		<controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')"
		@remove="$emit('remove')"></controler>

		<div v-if="data.selectedData.length">
			<el-carousel :interval='8000' height="180px" trigger="click" :arrow='showArrow'>
				<el-carousel-item v-for="(item,index) in data.selectedData" :key="index">
					<img style="height: 180px;object-fit: cover;width: 355px;margin-left: 10px;border-radius: 5px;" :src="item.bannerImgUrl?item.bannerImgUrl:'https://cdn.dkycn.cn/images/dyj/no-pic1.jpg'" alt="">
				</el-carousel-item>
			</el-carousel>
		</div>
		<div v-else>
			<el-carousel :interval='8000' height="180px" trigger="click" :arrow='showArrow'>
				<el-carousel-item>
					<img style="height: 180px;object-fit: cover;width: 355px;margin-left: 10px;border-radius: 5px;" src="https://cdn.dkycn.cn/images/dyj/no-pic1.jpg" alt="">
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- <div class="title" @click="edit();data.visible=true">{{data.text||"请输入文本内容"}}</div> -->
		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>
	</div>
</template>

<script>
	import moduleName from '../../moduleName';
	import controler from '../../controler';
	import edit from './edit';

	export default {
		name: 'normal-module',
		components: {
			moduleName,
			controler,
			edit
		},
		props: {
			name: {
				type: String,
				default: ''
			},
			data: {
				type: Object,
				default: () => {
					return {}
				}
			},

		},
		data() {
			return {
				showArrow:'never'
			};
		},
		methods: {
			edit() {
				this.$emit('visibleChange', this.data.visible)
			},

			close() {
				this.$emit('visibleChange', false)
			}

		},
		mounted() {},
		watch: {

		}
	};
</script>
