<style scoped lang="less">
	.singleRow-module-edit {
		// position: absolute;
		// right: -530px;
		width: 480px;
		padding: 20px 20px 30px;
		background: #fff;
		font-size: 14px;

		.title {
			position: relative;
			font-size: 18px;
			padding-bottom: 20px;
			margin-bottom: 25px;
			border-bottom: 1px solid #ddd;
		}

		.icon-close {
			position: absolute;
			right: 0;
			cursor: pointer;
		}

		.tip {
			margin-bottom: 20px;
		}

		.mod-footer {
			margin-top: 30px;
			padding-top: 20px;
			border-top: 1px solid #ddd;

			display: flex;

			.left {
				flex: 1 1 auto;
				padding-top: 6px;
			}
		}

	}

	.info {
		flex: 1 1 auto;
		margin-top: 10px;
		display: flex;
		overflow: hidden;
		height: 28px;
		align-items: center;

		.txt {
			flex: 0 0 auto;
			font-size: 14px;
			height: 19px;
		}

		.link-info {
			flex: 1 1 auto;
			overflow: hidden;
			max-width: 100%;
			overflow: hidden;

			display: flex;
			align-items: center;

			height: 28px;
			color: #fff;

			font-size: 12px;
			padding: 0 5px;
		}

		.cont {
			padding: 0 5px;
			background: #409eff;
			height: 28px;
			line-height: 28px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.icon {
			padding-right: 5px;
			background: #409eff;
			height: 28px;
			line-height: 32px;
		}
	}

	.upload-box {
		width: 240px;
		height: 32px;
		background: #eaedf1;
		text-align: center;
	}
	.add-btn{
		width: 440px;
		text-align: center;
		border: 1px dashed #ddd;
		font-size: 16px;
		color: #666;
		height: 48px;
		line-height: 48px;
		text-align: center;
		margin-top: 10px;
		cursor: pointer;
	}
	.item-container{
		margin-bottom: 10px;
		border: 1px solid #ddd;
		padding: 10px 5px;
		.item-image{
			display: flex;
			align-items: center;
			height: 100px;
			.item-right{
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 80px;
				margin-left: 10px;
				.right-top{
					display: flex;
					align-items: center;
					justify-content: flex-end;
					width: 240px;
					.img-desc{
						width: 160px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						text-align: center;
						padding: 0 10px;
					}
				}
				.right-bottom{
					display: flex;
					align-items: center;
					justify-content: flex-end;
					width: 240px;
					.page-desc{
						width: 160px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						text-align: center;
						padding: 0 10px;
						background: #eaedf1;
						text-align: center;
						height: 32px;
						line-height: 32px;
					}
				}
			}
		}
	}
</style>

<template>
	<!-- <transition name="scale"> -->
	<div class="singleRow-module-edit" v-if="visible">

		<p class="title">
			{{name}}
			<!-- <Icon  @click="close" type="ios-close-empty" size=32 class="icon-close" color="#999"></Icon> -->
		</p>
		
		<!-- <div>
			<div>组件样式:</div>
			<div style="display: flex;align-items: center;">
				<div style="width: 80px;">上间距：</div>
				<div style="display: flex;align-items: center;">
					<el-slider style="width: 300px;" v-model="data.topMargin" :show-tooltip="false"></el-slider>
					<div>{{data.topMargin}}</div>
				</div>
			</div>
			<div style="display: flex;align-items: center;">
				<div style="width: 80px;">下间距：</div>
				<div style="display: flex;align-items: center;">
					<el-slider style="width: 300px;" v-model="data.bottomMargin" :show-tooltip="false"></el-slider>
					<div>{{data.bottomMargin}}</div>
				</div>
			</div>
			<div style="display: flex;align-items: center;">
				<div style="width: 80px;">左右间距：</div>
				<div style="display: flex;align-items: center;">
					<el-slider style="width: 300px;" v-model="data.lrMargin" :show-tooltip="false"></el-slider>
					<div>{{data.lrMargin}}</div>
				</div>
			</div>
		</div> -->
		<div class="item-container" v-for="(item,index) in data.selectedData" :key='index'>
			<div class="item-image">
				<img style="width: 100px;height: 100px;" :src="item.bannerImgUrl ? item.bannerImgUrl :'https://cdn.dkycn.cn/images/dyj/no-pic1.jpg'" alt="">
				<div class="item-right">
					<el-upload class="upload-box" style="display: flex;align-items: center;" ref="upload" :action="imgApi"
					:show-file-list="false" accept="image/jpg, image/png, image/jpeg, image/gif" :on-success="handleSuccess"
					:on-error="handleFormatError">
						<div class="right-top">
							<div class="img-desc">{{item.bannerImgUrl}}</div>
							<el-button size="small" style="width: 80px;" type="primary" @click='upBtnClick(index)'>选择图片</el-button>
						</div>
					</el-upload>
					<div class="right-bottom">
						<div class="page-desc">{{item.pageData.text}}</div>
						<el-button size="small" style="width: 80px;" type="primary" @click="showModal(index)">选择页面</el-button>
					</div>
				</div>
				<div style="height: 80px;">
					<el-button size="small" type="infor" style="margin-left: 5px;" @click="deleteModal(index)">删除</el-button>
				</div>
			</div>
		</div>
		<div class="add-btn" @click="addBannerData">
			<i class="el-icon-plus" style="margin-right: 5px;vertical-align: -2px;font-size: 18px;"></i>添加选项
		</div>
		<div class="mod-footer">
			<div class="left">
				<el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></el-checkbox>
			</div>
		</div>

		<link-modal :showLiveTab="true" :visible="popVisible" @change="dataChange" @cancel="popVisible=false"></link-modal>

	</div>
	<!-- </transition> -->
</template>

<script>
	import linkModal from '@/views/components/linkModal';
	import config from '@/config/index';
	export default {
		name: 'normal-module-edit',
		components: {
			linkModal
		},
		props: {
			data: {
				type: Object,
				default: () => {
					return {}
				}
			},
			visible: {
				type: Boolean,
				default: false
			},
			name: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				popVisible: false,
				imgApi: config.UPLOAD_IMG,
				currentIndex:0
			};
		},
		methods: {
			close() {
				this.$emit('close')
			},
			saveData() {
				this.$emit('submit')
			},
			showModal(index) {
				this.currentIndex = index;
				this.popVisible = true;
			},
			dataChange(record) {
				console.log("--dataChange---", record)
				this.data.selectedData[this.currentIndex].pageData = record;
			},
			deleteLinkData() {
				//this.data.selectedData = {};
			},
			handleFormatError() {
				//this.$Message.error('上传失败！');
			},
			handleSuccess(res) {
				
				this.data.selectedData[this.currentIndex].bannerImgUrl = res.data[0];
				console.log('this.mainUrl', res);
			},
			upBtnClick(index){
				this.currentIndex = index;
			},
			addBannerData() {
				var obj = {
					bannerImgUrl: '',
					pageData: {
						text: ''
					}
				};
				this.data.selectedData.push(obj);
			},
			deleteModal(index){
				console.log("------",this.data);
				this.data.selectedData.splice(index,1);
			}

		},
		mounted() {
			// this.data.list.push({
			//         uid: this.guid(),
			//         imgUrl: '/ShopDecorate/61f677cf-ce0d-4364-b3d8-9cdeffe089aa.jpg',
			//         type: 1, //链接类型
			//         link: '', //自定义链接
			//         selectedData: {}, //弹框选中数据
			//     })
		},
		watch: {
			data() {
				//this.$set(this.data,'btnType',1)

				//this.data.btnType = 1;
				//console.log(value)
			}
		}
	};
</script>
