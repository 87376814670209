<style scoped lang="less">
	.search{
        flex: 0 0 auto;
        margin-bottom: 10px;
    }
    .tab-box{
        flex: 1 1 auto;
        margin-bottom: 10px;
    }
    .pagination{
        flex: 0 0 auto;
    }
	
	.shop-name{
		display: flex;
	}
	.shop-name .img{
		flex: 0 0 auto;
	}
	.shop-name .name{
		flex: 1 1 auto;
		overflow: hidden;
		display: flex;
		align-items: center;
	}
</style>
<template>
	<el-dialog :visible="show" width='990px' title="选择优惠券" @close="cancel">

		<div class="search">
			<span style="padding-right: 10px;">关键字：</span>
			<el-input v-model="keyword" style="width:200px;margin-right:10px" size="mini" placeholder="优惠券名称" />
			<el-button type="primary" size="mini" @click="pageIndex=1;getData();">搜索</el-button>
		</div>

		<div class="tab-box">
			<el-table :data="data" v-loading="loading" size="mini" :row-key="getRowKey" ref="tab" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
				<el-table-column label="优惠券" width="490px">
					<template slot-scope="scope">
						<div class="shop-name" style="overflow: hidden;">
							<div class="img" style="width: 73px;height: 50px;">
								<img src="https://api.static.huibogoulive.cn/images/melyshop/page-manage-point-coupon-icon.png" style="display: block;width: 100%;" />
							</div>
							<div class="name" style="margin-left: 10px;">

								<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>

							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="兑换价" width="200px">
					<template slot-scope="scope">
						{{scope.row.PointCount}}积分<span v-if="scope.row.PointPrice">+{{scope.row.PointPrice}}元</span>
					</template>
				</el-table-column>
				<el-table-column prop="RemainCount" label="剩余兑换数量" width="200px"></el-table-column>
			</el-table>
		</div>

		<div class="pagination">
			<el-pagination :current-page="pageIndex" :total="total" :page-size="pageSize" @current-change="pageChange"></el-pagination>
		</div>

		<div slot="footer">
			<el-button type="primary" @click="btnSure">确定</el-button>
		</div>

	</el-dialog>
</template>
<script>
	import {
		editSelectPointExchange
	} from '../../api/pageManage.js';
	import config from '@/config/index'

	export default {
		components: {},
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			isSingle: { //是否是单选
				type: Boolean,
				default: false
			},
			selectedList: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		data() {
			return {
				show: false,
				keyword: '',
				pageIndex: 1,
				pageSize: 10,
				total: 0,
				data: [],
				loading: false,
				selectionData: [],
				imgUrl: config.IMG_BASE
			};
		},
		methods: {
			cancel() {
				this.$emit('cancel');
			},
			async getData() {
				const params = {
					Keywords: this.keyword,
					Skip: (this.pageIndex - 1) * this.pageSize,
					Take: this.pageSize,
					Type: 1
				}

				this.loading = true;

				const result = await editSelectPointExchange(params);

				this.data = result.Result.Results;
				this.total = result.Result.Total;
				this.loading = false;

				if (!this.isReady) {
					if (this.$refs['tab']) {
						this.selectedList.map(item => {
							this.$refs['tab'].toggleRowSelection(item, true);
						})
						this.isReady = true;
					}
				}

			},
			getRowKey(row) {
				return row.ActivityFullId
			},
			pageChange(value) {
				this.pageIndex = value;
				this.getData();
			},
			//当前页全选
			handleSelectionChange(val) {
				this.selectionData = val;
			},
			// selectionChange(selection){
			//     this.selectionData = selection;
			// },
			// selectedRow(record, row){
			//     this.singleData = row;
			// },
			btnSure() {
				// if (this.isSingle && this.selectedData.length > this.disableList.length+1){
				//     this.$Message.error('修改产品只能单选！');
				//     return;
				// }

				this.$emit('change', this.selectionData);
				this.cancel();
			}
		},
		watch: {
			visible(value) {
				this.show = value;

				if (value) {
					if (this.$refs['tab']) {
						this.$refs['tab'].clearSelection()
						this.selectedList.map(item => {
							this.$refs['tab'].toggleRowSelection(item, true);
						})
					}
					this.getData();
				}
			}
		},
		mounted() {

		},
		created() {

		},
		dispatch() {

		}
	};
</script>
