<style scoped lang="less">
	.relative-box {
		position: relative;
		// height: 100%;
		width: 375px;
		// margin-left: 150px;
		flex: 0 0 auto;
		margin-top: 40px;
	}

	.browse-box {
		height: 100%;
		display: flex;
		flex-direction: column;
		background: #f5f5f5;
		box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);

		.title-box {
			flex: 0 0 auto;
		}

		.scroll-box {
			flex: 1 1 auto;
			overflow: auto;
			// width: 980px;
			margin-left: -40px;
			margin-right: -49px;
		}

		.cont-box {
			// margin-left: 40px;
			// margin-right: 548px;
			// width: 375px;
			width: 460px;
			padding-bottom: 140px;
		}

		.title {
			position: relative;

			img {
				display: block;
				width: 100%;
			}

			.text {
				position: absolute;
				bottom: 0;
				width: 80%;
				height: 40px;
				left: 10%;
				line-height: 40px;
				text-align: center;
				font-size: 16px;
				color: #333;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin: 0 auto;
			}
		}

		.item {
			position: relative;
		}

		.cover-scroll-bar {
			position: absolute;
			right: -565px;
			background: #e5e5e5;
			top: 0;
			bottom: 0;
			width: 20px;
		}

		.cover-scroll-bar1 {
			position: absolute;
			background: #e5e5e5;
			left: -40px;
			bottom: 0;
			height: 20px;
			right: -580px;
		}
	}

	.module-item {}

	::-webkit-scrollbar {
		width: 0px;
	}

	::-webkit-scrollbar-track {
		background-color: none;
	}

	::-webkit-scrollbar-thumb {
		background-color: none;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: none;
	}

	::-webkit-scrollbar-thumb:active {
		background-color: none;
	}
</style>

<template>
	<div class="relative-box">
		<div class="browse-box">
			<div class="title-box" :key="item.uid" v-for="item in list">
				<div class="title" :style="{background:item.data.titleBg}" v-if="item.moduleType===1">
					<img src="../../../assets/img/title-bg.png" />
					<p class="text">{{item.data.title}}</p>
					<!-- 默认模块 -->
					<normal-module v-if="item.moduleType===1" class="item" :key="item.uid" :data="item.data" :name="item.name" @submit="$emit('submit')"
					@visibleChange="value=>{visibleChange(item, value)}" @moveUp="$emit('moveUp', index)" @moveDown="$emit('moveDown', index)"
					@remove="$emit('remove', index)" style="top: -60px">
					</normal-module>
				</div>
			</div>
			<div :key="index" v-for="(item,index) in list">
				<div v-if="item.moduleType===1">
					<!-- <div v-if="item.data.titleBg" style="position: absolute;top: 0;left: 0;right: 0;height: 90px;text-align: center;line-height: 90px;" :style="{background:item.data.titleBg}">{{item.data.title}}</div> -->
					<div v-if="item.data.pageBg" style="position: absolute;top: 88px;bottom: 0;left: 0;right: 0;" :style="{background:item.data.pageBg}"></div>
				</div>
			</div>
			<div class="scroll-box" ref="scroll">
				<div class="cont-box" ref="cont">
					<!-- <draggable class="scroll-box" :move="getdata" @update="datadragEnd" :options="{animation: 300, draggable:'.item', sort: false, group: 'page'}"> -->
					<div class="module-item" :key="index" :id="item.uid" v-for="(item, index) in list">


						<!-- 单排产品 -->
						<singleRowProduct v-if="item.moduleType===2" class="item" :key="item.uid" :data="item.data" :name="item.tipName"
						@submit="$emit('submit')" @visibleChange="value=>{visibleChange(item, value)}" @moveUp="$emit('moveUp', index)"
						@moveDown="$emit('moveDown', index)" @remove="$emit('remove', index)">
						</singleRowProduct>

						<!-- 双排产品 -->
						<!-- <doubleRowProduct  
                            v-if="item.moduleType===3" 
                            class="item" 
                            :key="item.uid" 
                            :data="item.data" 
                            :name="item.name"
                            @submit="$emit('submit')" 
                            @visibleChange="value=>{visibleChange(item, value)}" 
                            @moveUp="$emit('moveUp', index)" 
                            @moveDown="$emit('moveDown', index)" 
                            @remove="$emit('remove', index)"
                            >
                        </doubleRowProduct> -->

						<!-- 三排产品 -->
						<!-- <thirdRowProduct  
                            v-if="item.moduleType===4" 
                            class="item" 
                            :key="item.uid" 
                            :data="item.data" 
                            :name="item.name"
                            @submit="$emit('submit')" 
                            @visibleChange="value=>{visibleChange(item, value)}" 
                            @moveUp="$emit('moveUp', index)" 
                            @moveDown="$emit('moveDown', index)" 
                            @remove="$emit('remove', index)"
                            >
                        </thirdRowProduct> -->

						<!-- 商品列表 -->
						<!-- <productList  
                            v-if="item.moduleType===5" 
                            class="item" 
                            :key="item.uid" 
                            :data="item.data" 
                            :name="item.name"
                            @submit="$emit('submit')" 
                            @visibleChange="value=>{visibleChange(item, value)}" 
                            @moveUp="$emit('moveUp', index)" 
                            @moveDown="$emit('moveDown', index)" 
                            @remove="$emit('remove', index)"
                            >
                        </productList> -->

						<!-- 猜你喜欢 -->
						<guessYourLike v-if="item.moduleType===6" class="item" :key="item.uid" :data="item.data" :name="item.tipName"
						@submit="$emit('submit')" @visibleChange="value=>{visibleChange(item, value)}" @moveUp="$emit('moveUp', index)"
						@moveDown="$emit('moveDown', index)" @remove="$emit('remove', index)">
						</guessYourLike>

						<!-- 视频模块 -->
						<!-- <videoModule  
                            v-if="item.moduleType===7" 
                            class="item" 
                            :key="item.uid" 
                            :data="item.data" 
                            :name="item.name"
                            @submit="$emit('submit')" 
                            @visibleChange="value=>{visibleChange(item, value)}" 
                            @moveUp="$emit('moveUp', index)" 
                            @moveDown="$emit('moveDown', index)" 
                            @remove="$emit('remove', index)"
                            >
                        </videoModule> -->

						<!-- 轮播图 -->
						<!-- <slideModule  
                            v-if="item.moduleType===8" 
                            class="item" 
                            :key="item.uid" 
                            :data="item.data" 
                            :name="item.name"
                            @submit="$emit('submit')" 
                            @visibleChange="value=>{visibleChange(item, value)}" 
                            @moveUp="$emit('moveUp', index)" 
                            @moveDown="$emit('moveDown', index)" 
                            @remove="$emit('remove', index)"
                            >
                        </slideModule> -->

						<!-- 单排图片 -->
						<singleRowPicture v-if="item.moduleType===9" class="item" :key="item.uid" :data="item.data" :name="item.tipName"
						@submit="$emit('submit')" @visibleChange="value=>{visibleChange(item, value)}" @moveUp="$emit('moveUp', index)"
						@moveDown="$emit('moveDown', index)" @remove="$emit('remove', index)">
						</singleRowPicture>

						<!-- 双排图片 -->
						<!-- <doubleRowPicture  
                            v-if="item.moduleType===10" 
                            class="item" 
                            :key="item.uid" 
                            :data="item.data" 
                            :name="item.name"
                            @submit="$emit('submit')" 
                            @visibleChange="value=>{visibleChange(item, value)}" 
                            @moveUp="$emit('moveUp', index)" 
                            @moveDown="$emit('moveDown', index)" 
                            @remove="$emit('remove', index)"
                            >
                        </doubleRowPicture> -->

						<!-- 图文导航 -->
						<!-- <pictureNavigation  
                            v-if="item.moduleType===11" 
                            class="item" 
                            :key="item.uid" 
                            :data="item.data" 
                            :name="item.name"
                            @submit="$emit('submit')" 
                            @visibleChange="value=>{visibleChange(item, value)}" 
                            @moveUp="$emit('moveUp', index)" 
                            @moveDown="$emit('moveDown', index)" 
                            @remove="$emit('remove', index)"
                            >
                        </pictureNavigation> -->

						<!-- 热区 -->
						<hotArea :ref="'hot'+item.uid" v-if="item.moduleType===12" class="item" :key="item.uid" :data="item.data" :name="item.tipName"
						@submit="$emit('submit')" @visibleChange="value=>{visibleChange(item, value)}" @moveUp="$emit('moveUp', index)"
						@moveDown="$emit('moveDown', index)" @remove="$emit('remove', index)">
						</hotArea>

						<!-- 标签定向栏 -->
						<!-- <labelOriginTools  
                            v-if="item.moduleType===13" 
                            class="item" 
                            :key="item.uid" 
                            :data="item.data" 
                            :name="item.name"
                            @submit="$emit('submit')" 
                            @visibleChange="value=>{visibleChange(item, value)}" 
                            @moveUp="$emit('moveUp', index)" 
                            @moveDown="$emit('moveDown', index)" 
                            @remove="$emit('remove', index)"
                            >
                        </labelOriginTools> -->

						<!-- 标题 -->
						<!-- <titleModule  
                            v-if="item.moduleType===14" 
                            class="item" 
                            :key="item.uid" 
                            :data="item.data" 
                            :name="item.name"
                            @submit="$emit('submit')" 
                            @visibleChange="value=>{visibleChange(item, value)}" 
                            @moveUp="$emit('moveUp', index)" 
                            @moveDown="$emit('moveDown', index)" 
                            @remove="$emit('remove', index)"
                            >
                        </titleModule> -->

						<!-- 文本 -->
						<textModule v-if="item.moduleType===15" class="item" :key="item.uid" :data="item.data" :name="item.tipName"
						@submit="$emit('submit')" @visibleChange="value=>{visibleChange(item, value)}" @moveUp="$emit('moveUp', index)"
						@moveDown="$emit('moveDown', index)" @remove="$emit('remove', index)">
						</textModule>

						<!-- 搜索 -->
						<searchModule v-if="item.moduleType===16" class="item" :key="item.uid" :data="item.data" :name="item.tipName"
						@submit="$emit('submit')" @visibleChange="value=>{visibleChange(item, value)}" @moveUp="$emit('moveUp', index)"
						@moveDown="$emit('moveDown', index)" @remove="$emit('remove', index)">
						</searchModule>

						<!-- 秒杀 -->
						<flashSale v-if="item.moduleType===17" class="item" :key="item.uid" :data="item.data" :name="item.tipName"
						@submit="$emit('submit')" @visibleChange="value=>{visibleChange(item, value)}" @moveUp="$emit('moveUp', index)"
						@moveDown="$emit('moveDown', index)" @remove="$emit('remove', index)">
						</flashSale>

						<!-- 优惠券 -->
						<couponModule v-if="item.moduleType===18" class="item" :key="item.uid" :data="item.data" :name="item.tipName"
						@submit="$emit('submit')" @visibleChange="value=>{visibleChange(item, value)}" @moveUp="$emit('moveUp', index)"
						@moveDown="$emit('moveDown', index)" @remove="$emit('remove', index)">
						</couponModule>

						<!-- 拼团 -->
						<!-- <groupBuying  
                            v-if="item.moduleType===19" 
                            class="item" 
                            :key="item.uid" 
                            :data="item.data" 
                            :name="item.name"
                            @submit="$emit('submit')" 
                            @visibleChange="value=>{visibleChange(item, value)}" 
                            @moveUp="$emit('moveUp', index)" 
                            @moveDown="$emit('moveDown', index)" 
                            @remove="$emit('remove', index)"
                            >
                        </groupBuying> -->

						<!-- 积分商城 -->
						<pointShop v-if="item.moduleType===20" class="item" :key="item.uid" :data="item.data" :name="item.tipName"
						@submit="$emit('submit')" @visibleChange="value=>{visibleChange(item, value)}" @moveUp="$emit('moveUp', index)"
						@moveDown="$emit('moveDown', index)" @remove="$emit('remove', index)">
						</pointShop>

						<!-- 公告模块 -->
						<noticeModule v-if="item.moduleType===21" class="item" :key="item.uid" :data="item.data" :name="item.tipName"
						@submit="$emit('submit')" @visibleChange="value=>{visibleChange(item, value)}" @moveUp="$emit('moveUp', index)"
						@moveDown="$emit('moveDown', index)" @remove="$emit('remove', index)">
						</noticeModule>

						<!-- 直播模块 -->
						<liveModule v-if="item.moduleType===22" class="item" :key="item.uid" :data="item.data" :name="item.tipName"
						@submit="$emit('submit')" @visibleChange="value=>{visibleChange(item, value)}" @moveUp="$emit('moveUp', index)"
						@moveDown="$emit('moveDown', index)" @remove="$emit('remove', index)">
						</liveModule>

						<!-- 新品 -->
						<new-product v-if="item.moduleType===23" class="item" :key="item.uid" :data="item.data" :name="item.tipName"
						@submit="$emit('submit')" @visibleChange="value=>{visibleChange(item, value)}" @moveUp="$emit('moveUp', index)"
						@moveDown="$emit('moveDown', index)" @remove="$emit('remove', index)">
						</new-product>

						<!-- 社群专享品 -->
						<wechatGroup v-if="item.moduleType===24" class="item" :key="item.uid" :data="item.data" :name="item.tipName"
						@submit="$emit('submit')" @visibleChange="value=>{visibleChange(item, value)}" @moveUp="$emit('moveUp', index)"
						@moveDown="$emit('moveDown', index)" @remove="$emit('remove', index)">
						</wechatGroup>

						<!-- 社群绑定 -->
						<bindGroup v-if="item.moduleType===25" class="item" :key="item.uid" :data="item.data" :name="item.tipName"
						@submit="$emit('submit')" @visibleChange="value=>{visibleChange(item, value)}" @moveUp="$emit('moveUp', index)"
						@moveDown="$emit('moveDown', index)" @remove="$emit('remove', index)">
						</bindGroup>

						<!-- 轮播图 -->
						<bannerModule v-if="item.moduleType===26" class="item" :key="item.uid" :data="item.data" :name="item.name"
						@submit="$emit('submit')" @visibleChange="value=>{visibleChange(item, value)}" @moveUp="$emit('moveUp', index)"
						@moveDown="$emit('moveDown', index)" @remove="$emit('remove', index)">
						</bannerModule>
						<!-- 富文本 -->
						<richTextModule v-if="item.moduleType===27" class="item" :key="item.uid" :data="item.data" :name="item.tipName"
						@submit="$emit('submit')" @visibleChange="value=>{visibleChange(item, value)}" @moveUp="$emit('moveUp', index)"
						@moveDown="$emit('moveDown', index)" @remove="$emit('remove', index)">
						</richTextModule>
					</div>
				</div>

				<!-- </draggable> -->
			</div>
			<!-- <div class="cover-scroll-bar"></div>
            <div class="cover-scroll-bar1"></div> -->
		</div>
	</div>
</template>

<script>
	import draggable from 'vuedraggable';

	import {
		normalModule, //默认模块
		singleRowProduct, //单排产品
		doubleRowProduct, //双排产品
		thirdRowProduct, //三排产品
		productList, //商品列表
		guessYourLike, //猜你喜欢
		videoModule, //视频模块
		slideModule, //轮播图
		singleRowPicture, //单排图片
		doubleRowPicture, //双排图片
		pictureNavigation, //图文导航
		hotArea, //热区链接
		labelOriginTools, //标签定向栏
		titleModule, // 标题
		textModule, //文本
		searchModule, //搜索
		flashSale, //限时抢购
		couponModule, //优惠券
		groupBuying, //拼团
		pointShop, //积分商城
		noticeModule, //公告模块
		liveModule, // 直播模块
		newProduct, //新品
		wechatGroup, //社群专享品
		bindGroup, //社群绑定，
		bannerModule,//轮播图
		richTextModule,//富文本
	} from './browseModule';


	export default {
		name: 'browse-manage',
		components: {
			normalModule,
			singleRowProduct,
			doubleRowProduct,
			thirdRowProduct,
			productList,
			guessYourLike,
			videoModule,
			slideModule,
			singleRowPicture,
			doubleRowPicture,
			pictureNavigation,
			hotArea,
			labelOriginTools,
			titleModule,
			textModule,
			searchModule,
			flashSale,
			couponModule,
			groupBuying,
			draggable,
			pointShop,
			noticeModule,
			liveModule,
			newProduct,
			wechatGroup,
			bindGroup,
			bannerModule,
			richTextModule,
		},
		props: {
			list: {
				type: Array,
				default: () => {
					return []
				}
			},
			isSetData: {
				type: Boolean,
				default: false,
			}
		},
		data() {
			return {
				dataList: [],
			};
		},
		methods: {
			getdata: function(evt) {
				return evt.target.className !== 'item-box';
			},
			datadragEnd: function(evt) {
				console.log('拖动前的索引：' + evt.oldIndex);
				console.log('拖动后的索引：' + evt.newIndex);
			},
			visibleChange(record) {

				this.list.map(item => {

					if (item.uid === record.uid) {
						// item.data.visible = true;
						item.data.editVisible = true;
					} else {
						// item.data.visible = false;
						item.data.editVisible = false;
					}

					return item;

				})

			},
			hotMove(record, index, uid) {
				console.log(this.$refs['hot' + uid])
				this.$refs['hot' + uid][0].handelCanMove(record, index)
				console.log(record, index)
			}
		},
		mounted() {
			//计算滚动条宽度
			// const scrollWidth = this.$refs.scroll.offsetWidth - this.$refs.cont.offsetWidth;

			// var el = this.$refs.dragable.$children[1].$el.children[1];
			// let vm = this;
			// Sortable.create(el, {
			//     onStart: vm.startFunc,
			//     onEnd: vm.endFunc,
			//     onChoose: vm.chooseFunc
			// });
		},
		watch: {
			list(value) {
				//this.dataList = value;
				console.log("--------", value)
			},
			isSetData() {
				console.log('change')
				setTimeout(() => {
					this.$refs['scroll'].scrollTo(0, 1000000)
				}, 100)

			}
		}
	};
</script>
