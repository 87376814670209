<style scoped lang="less">
	.singleRowProduct-module {

		.coupon-list {
			padding: 0 10px;
			display: flex;

		}
	}
	
	.coupon-item {
		margin-bottom: 20upx;
		position: relative;
		width: 359px ;
		height: 116upx;
	
		&:last-child {
			margin-bottom: 0;
		}
	
		>img {
			width: 100%;
			height: 100%;
		}
	
		.coupon_detail {
			position: absolute;
			display: flex;
			align-items: center;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
	
			.coupon_left {
				position: absolute;
				left: 0;
				color: #EF761B;
				width: 268px;
				height: 100%;
				display: flex;
				align-items: center;
	
				.coupon-price {
					width: 97px;
					margin-left: 10px;
					margin-right: 8px;
					font-weight: bold;
				}
	
				.coupon-info {
					flex: 1;
					font-size: 12px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					margin-right: 6px;
					font-family: PingFangSC-Regular, PingFang SC;
	
					.coupon-name {
						font-size: 18px;
						font-weight: bold;
						margin-bottom: 1px;
					}
	
					.coupon-desc {
						width: 150px;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
					}
				}
			}
	
			.coupon_right {
				position: absolute;
				right: 0;
				width: 90px;
				display: flex;
				align-items: center;
				height: 100%;
				justify-content: center;
	
				.coupon_tag {
					width: 62px;
					height: 28px;
					background: linear-gradient(297deg, #F3A11E 0%, #EF761A 100%);
					border-radius: 14px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-size: 12px;
					font-weight: bold;
					text-align: center;
					line-height: 28px;
					color: #fff;
				}
			}
		}
	}
	
	.second-style {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #fff;
		flex-wrap: wrap;
		.item-second {
			position: relative;
			width: 170px;
			height: 100px;
			margin-bottom: 10px;
			display: flex;
			align-items: center;
			margin-right: 5px;
	
			>img {
				width: 170px;
				height: 100px;
			}
	
			&:last-child {
				// margin-bottom: 0;
				margin-right: 0;
			}
	
			.coupon_detail {
				position: absolute;
				display: flex;
				align-items: center;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
	
				.left-detail {
					padding: 0 13px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					height: 100%;
				}
	
				.coupon_left {
					position: absolute;
					left: 0;
					color: #EF761B;
					width: 125px;
					height: 100%;
	
					.coupon-price {
						width: 83px;
						margin-left: 10px;
						margin-right: 8px;
						font-weight: bold;
					}
	
					.coupon-info {
						font-size: 10px;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						margin-right: 6px;
						font-family: PingFangSC-Regular, PingFang SC;
	
						.coupon-name {
							font-size: 14px;
							font-weight: bold;
							margin-bottom: 1px;
						}
	
						.coupon-desc {
							width: 95px;
							margin-left: 5px;
							text-align: center;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
						}
					}
				}
	
				.coupon_right {
					position: absolute;
					right: 0;
					width: 47px;
					display: flex;
					align-items: center;
					height: 100%;
					justify-content: center;
	
					.coupon_tag {
						width: 38px;
						height: 18px;
						background: linear-gradient(297deg, #F3A11E 0%, #EF761A 100%);
						border-radius: 9px;
						font-family: PingFangSC-Semibold, PingFang SC;
						font-size: 10px;
						font-weight: bold;
						text-align: center;
						line-height: 18px;
						color: #fff;
					}
				}
			}
		}
	}
	
	.slide-style {
		display: flex;
		align-items: center;
	
		.item-slide {
			position: relative;
			display: flex;
			align-items: center;
			width: 160px;
			height: 100px;
			margin-right: 10px;
			
			>img {
				width: 160px;
				height: 100px;
			}
	
			&:last-child {
				margin-bottom: 0;
			}
	
			.coupon_detail {
				position: absolute;
				display: flex;
				align-items: center;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
	
				.left-detail {
					padding: 0 10px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					height: 100%;
				}
	
				.coupon_left {
					position: absolute;
					left: 0;
					color: #EF761B;
					width: 160px;
					height: 100%;
	
					.coupon-price {
						// width: 166px;
						margin-left: 10px;
						margin-right: 8px;
						font-weight: bold;
					}
	
					.coupon-info {
						font-size: 12px;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						font-family: PingFangSC-Regular, PingFang SC;
	
						.coupon-name {
							font-size: 14px;
							font-weight: bold;
							margin-bottom: 1px;
						}
	
						.coupon-desc {
							width: 130px;
							margin-left: 10px;
							text-align: center;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
						}
					}
				}
			}
		}
	}

	.haveSpace {
		margin-bottom: 10px;
	}

</style>

<template>
	<div class="singleRowProduct-module" @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;"
	:class="{haveSpace: !data.hideSpace}">
		<module-name :name="name"></module-name>
		<controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')"
		@remove="$emit('remove')"></controler>
		<!-- <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  /> -->

		<div class="coupon-list">
			<!-- @click="edit();data.visible=true" -->
			<div v-if="data.list.length">

				<div v-if="data.layoutStyle=='1'" class="coupon-item" v-for="item in data.list" :key="item.id">
					<img src="https://api.static.huibogoulive.cn/test/goods/pic/202012/242c765d-e5a1-435b-85b8-cadc3623f1b4.png"/>
					<!-- <img src="https://api.static.huibogoulive.cn/test/goods/pic/202012/1d38aeb3-bf30-4083-a400-5c63ca341768.png" mode="aspectFit"></img> -->
					<div class="coupon_detail">
						<div class="coupon_left">
							<div class="coupon-price">
								<span style="font-size: 14px;">￥</span>
								<span style="font-size: 30px;">{{item.faceMoney}}</span>
								<span style="font-size: 14px;">元</span>
							</div>
							<div class="coupon-info">
								<div class="coupon-name">{{item.couponName}}</div>
								<div class="coupon-desc">{{item.shold==0?'无门槛':'满'+item.sholdMoney+'可用'}}，{{item.applyType==0?'全部商品可用':'部分商品可用'}}</div>
							</div>
						</div>
						<div class="coupon_right">
							<div class="coupon_tag">领取</div>
						</div>
					</div>
				</div>
				<div class="second-style" v-if="data.layoutStyle=='2'">
					<div class="item-second" v-for="item in data.list" :key="item.id">
						<img style="position: absolute;left: 0;right: 0;" src="https://api.static.huibogoulive.cn/test/goods/pic/202012/084baa46-19e1-4ed8-ad4b-7a91717c9045.png"/>
						<!-- <img style="position: absolute;left: 0;right: 0;" src="https://api.static.huibogoulive.cn/test/goods/pic/202012/37a01d84-db70-44f4-bfd8-2451c4672f32.png"
						mode="aspectFill"></img> -->
						<div class="coupon_detail">
							<div class="coupon_left">
								<div class="left-detail">
									<div class="coupon-price">
										<span style="font-size: 9px;">￥</span>
										<span style="font-size: 26px;">{{item.faceMoney}}</span>
										<span style="font-size: 10px;">元</span>
									</div>
									<div class="coupon-info">
										<div class="coupon-desc">{{item.shold==0?'无门槛':'满'+item.sholdMoney+'可用'}}，{{item.applyType==0?'全部商品可用':'部分商品可用'}}</div>
									</div>
								</div>
							</div>
							<div class="coupon_right">
								<div class="coupon_tag">领取</div>
							</div>
						</div>
					</div>
				</div>
				
				<div v-if="data.layoutStyle=='3'" style="display: flex;align-items: center;overflow: hidden;width: 355px;">
					<div class="slide-style">
						<div class="item-slide" v-for="item in data.list" :key="item.id">
							<img src="https://api.static.huibogoulive.cn/test/goods/pic/202012/b61157bd-1274-4907-ab8a-8f3e68a857fd.png" />
							<!-- <img style="position: absolute;left: 0;right: 0;" src="https://api.static.huibogoulive.cn/test/goods/pic/202012/cde716a4-ad97-46a4-a9b6-d3934c39de95.png"
							mode="aspectFill"></img> -->
							<div class="coupon_detail">
								<div class="coupon_left">
									<div class="left-detail">
										<div class="coupon-price">
											<span style="font-size: 9px;">￥</span>
											<span style="font-size: 26px;">{{item.faceMoney}}</span>
											<span style="font-size: 10px;">元</span>
										</div>
										<div class="coupon-info">
											<div class="coupon-desc">{{item.shold==0?'无门槛':'满'+item.sholdMoney+'可用'}}，{{item.applyType==0?'全部商品可用':'部分商品可用'}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				<div class="coupon-item" >
					<img src="https://api.static.huibogoulive.cn/test/goods/pic/202012/242c765d-e5a1-435b-85b8-cadc3623f1b4.png"/>
					<!-- <img src="https://api.static.huibogoulive.cn/test/goods/pic/202012/1d38aeb3-bf30-4083-a400-5c63ca341768.png" mode="aspectFit"></img> -->
					<div class="coupon_detail">
						<div class="coupon_left">
							<div class="coupon-price">
								<span style="font-size: 14px;">￥</span>
								<span style="font-size: 30px;">10</span>
								<span style="font-size: 14px;">元</span>
							</div>
							<div class="coupon-info">
								<div class="coupon-name">优惠券名称</div>
								<div class="coupon-desc">无门槛使用</div>
							</div>
						</div>
						<div class="coupon_right">
							<div class="coupon_tag">领取</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>

	</div>
</template>

<script>
	import moduleName from '../../moduleName';
	import controler from '../../controler';
	import edit from './edit';

	export default {
		name: 'normal-module',
		components: {
			moduleName,
			controler,
			edit
		},
		props: {
			name: {
				type: String,
				default: ''
			},
			data: {
				type: Object,
				default: () => {
					return {}
				}
			},

		},
		data() {
			return {};
		},
		methods: {
			edit() {
				this.$emit('visibleChange', this.data.visible)
			},

			close() {
				this.$emit('visibleChange', false)
			}

		},
		mounted() {
			// console.log(this.data)
		},
		watch: {
			data() {
				// console.log(value)
			}
		}
	};
</script>
