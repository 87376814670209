<style scoped lang="less">
    .singleRow-module-edit{
        position: absolute;
        // right: -530px;
        // width: 480px;
        padding: 20px 20px 30px;
        background: #fff;
        font-size: 14px;

        .title{
            position: relative;
            font-size: 18px;
            padding-bottom: 20px;
            margin-bottom: 25px;
            border-bottom: 1px solid #ddd;
        }

        .icon-close{
            position: absolute;
            right: 0;
            cursor: pointer;
        }

        .tip{
            margin-bottom: 20px;
        }

        .mod-footer{
            margin-top: 30px;
            padding-top: 20px;
            // border-top: 1px solid #ddd;

            display: flex;

            .left{
                flex: 1 1 auto;
                padding-top: 6px;
            }
        }

    }
	
	.info{
	    flex: 1 1 auto;
	    margin-top: 10px;
		
		display: flex;
		overflow: hidden;
		height: 28px;
		align-items: center;
		
		.txt{
			flex: 0 0 auto;
			font-size: 14px;
			height: 19px;
		}
		
		.link-info{
			flex: 1 1 auto;
			overflow: hidden;
			max-width: 100%;
			overflow: hidden;
			
			display: flex;
			align-items: center;
			
			height: 28px;
			color: #fff;
			
			font-size: 12px;
			padding: 0 5px;
		}
		
		.cont{
			padding: 0 5px;
			background: #409eff;
			height: 28px;
			line-height: 28px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.icon{
			padding-right:5px;
			background: #409eff;
			height: 28px;
			line-height: 32px;
		}
	}

    
</style>

<template> 
    <!-- <transition name="scale"> -->
        <div class="singleRow-module-edit" v-if="visible">
            
            <p class="title">
                {{name}}设置 
                <!-- <Icon  @click="close" type="ios-close-empty" size=32 class="icon-close" color="#999"></Icon> -->
            </p>
			
			<div class="baseBottom-box" style="margin-bottom:100px;">
				<quill-editor ref="myTextEditor" v-model="data.text" :options="quillOption" style="width:100%;min-height: 300px;height:450px;"></quill-editor>
			</div>

            <div class="mod-footer">
                <div class="left">
                    <el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></el-checkbox>
                </div>
            </div>
			
			<link-modal :showLiveTab="true" :visible="popVisible" @change="dataChange" @cancel="popVisible=false"></link-modal>

        </div>
    <!-- </transition> -->
</template>

<script>
import linkModal from '@/views/components/linkModal';
import {
		quillEditor
	} from 'vue-quill-editor'
	import quillConfig from '@/components/quill-config.js'
export default {
    name: 'normal-module-edit',
    components: {
		linkModal
    },
    props: {
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        visible: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
			popVisible: false,
			quillOption: quillConfig, //富文本
        };
    },
    methods: {
        close(){
            this.$emit('close')
        },
        saveData(){
            this.$emit('submit')
        },
		showModal(){
			this.popVisible = true;
		},
		dataChange(record){
			this.data.selectedData = record;
		},
		deleteLinkData(record){
			this.data.selectedData = {};
		},

    },
    mounted () {
        // this.data.list.push({
        //         uid: this.guid(),
        //         imgUrl: '/ShopDecorate/61f677cf-ce0d-4364-b3d8-9cdeffe089aa.jpg',
        //         type: 1, //链接类型
        //         link: '', //自定义链接
        //         selectedData: {}, //弹框选中数据
        //     })
    },
    watch: {
        data(value){
            //this.$set(this.data,'btnType',1)
            
            //this.data.btnType = 1;
            //console.log(value)
        }
    }
};
</script>

