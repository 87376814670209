<style scoped lang="less">
	.singleRowProduct-module {

		.product-list {
			display: flex;
			flex-wrap: wrap;
			overflow: hidden;
			padding: 10px 8px 5px;
			background: #fff;
			// justify-content: space-between;

			.product-item {
				position: relative;
				float: left;
				width: 116px;
				height: 215px;
				background: #fff;

				margin-right: 5px;
				margin-bottom: 10px;
				box-shadow: 0 3px 2px 0px rgba(99, 99, 99, 0.1);
				border-radius: 6px;

				&:nth-child(3n) {
					margin-right: 0;
				}

				.img {
					width: 116px;
					height: 116px;
					border-radius: 6px 6px 0 0;
					overflow: hidden;

					img {
						display: block;
						width: 100%;
					}
				}

				.name {
					margin: 5px 5px 10px;
					font-size: 12px;
					line-height: 16px;
					height: 32px;

					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}

				.sellpoint {
					margin: 5px 5px 10px;
					font-size: 12px;
					line-height: 16px;
					max-height: 32px;
					color: #999;

					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}

				.pro-info {
					display: flex;
					margin: 0 5px;
					align-items: center;

					.left {
						flex: 1 1 auto;
						color: #ff547b;
						font-weight: bold;
						font-size: 14px;

						display: flex;
						align-items: baseline;

						.old {
							font-size: 12px;
							color: #999;
							margin-left: 10px;
							text-decoration: line-through;
							font-weight: 400;
						}
					}

					.right {
						flex: 0 0 auto;
						width: 13px;
						height: 12px;

						img {
							display: block;
							width: 100%;
						}
					}
				}

				.tag-box {
					position: absolute;
					left: 5px;
					top: 5px;
					width: 18px;
					padding: 5px 0;
					color: #fff;
					font-size: 12px;
					border-radius: 9px;
					background: #f00000;
					text-align: center;
				}

				.btn {
					height: 26px;
					line-height: 26px;
					text-align: center;
					background-color: #ff547b;
					color: #fff;
					font-size: 12px;
					margin-top: 10px;
				}
			}

			.product-item-column2 {
				width: 174px;
				height: 250px;

				&:nth-child(3n) {
					margin-right: 5;
				}

				&:nth-child(2n) {
					margin-right: 0;
				}

				.img {
					width: 174px;
					height: 174px;
				}
			}

			.product-item-column1 {
				width: 360px;
				height: auto;
				padding-bottom: 10px;

				&:nth-child(3n) {
					margin-right: 0;
				}

				&:nth-child(2n) {
					margin-right: 0;
				}

				.img {
					width: 360px;
					height: 360px;
				}

				.name {
					font-size: 14px;
					line-height: 18px;
					height: auto;
					max-height: 36px;
					margin-bottom: 0;
				}

				.pro-info .left {
					font-size: 16px;
				}

				.pro-info .right {
					width: 16px;
					height: 15px;
				}

			}

		}

		.listSlide {
			display: flex;

			.product-item {
				float: none;

				&:nth-child(3n) {
					margin-right: 5px;
				}
			}
		}

	}

	.haveSpace {
		margin-bottom: 10px;
	}

	.tabs {
		height: 42px;
		border-bottom: 1px solid #eee;
		display: flex;
		align-items: center;
		padding-left: 10px;
		// margin-right: 10px;
		background: #fff;
		overflow: auto;

		.tab {
			height: 24px;
			line-height: 24px;
			padding: 0 15px;
			border-radius: 12px;
			font-size: 14px;
			color: #666;
			background: #fff;
			white-space: nowrap;
			margin-right: 15px;
		}

		.current {
			background: #ff547b;
			color: #fff;
		}
	}

	::-webkit-scrollbar {
		width: 0px;
		height: 0;
	}

	::-webkit-scrollbar-track {
		background-color: none;
	}

	::-webkit-scrollbar-thumb {
		background-color: none;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: none;
	}

	::-webkit-scrollbar-thumb:active {
		background-color: none;
	}

	.tabsScroll {
		overflow-x: auto;
		overflow-y: hidden;
		white-space: nowrap;
		padding-bottom: 5px;
		display: flex;
		align-items: center;
		width: 100%;
	}

	.product-item-slide {
		width: 110px;
		border-radius: 4px;
		margin-right: 5px;
		border: 0.5px solid #EFEFEF;

		.img {
			position: relative;
			flex: 0 0 auto;
			width: 109px;
			height: 109px;
			overflow: hidden;
			border-radius: 5px 5px 0 0;

			>img {
				display: block;
				width: 109px;
				height: 109px;
			}

			.kill-icon {
				position: absolute;
				left: 0;
				top: 0;
				width: 55px;
				height: 22px;
			}
		}

		.right {
			flex: 1 1 auto;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			.top {
				// height: 32px;
				overflow: hidden;
				margin: 4px 4px 0 4px;
			}

			.name {
				font-size: 12px;
				color: #333;
				font-weight: bold;
				line-height: 17px;
			}

			.point-name {
				font-size: 11px;
				overflow: hidden;
				color: #666;
				height: 16px;
				font-weight: normal;
				line-height: 16px;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			.price {
				display: flex;
				margin: 4px;

				.left {
					font-size: 18px;
					color: #ff547b;
					flex: 1 1 auto;
					font-weight: bold;
					margin-right: 10px;
					white-space: nowrap;
					display: flex;
					align-items: center;

					.old {
						font-size: 8px;
						color: #999;
						font-weight: normal;
						padding-top: 4px;
						margin-left: 3px;
						text-decoration: line-through;
					}
				}
			}

			.btn {
				width: 108px;
				height: 28px;
				line-height: 28px;
				text-align: center;
				font-size: 14px;
				color: #fff;
				margin-top: 4px;
				border-radius:0 0 4px 4px;
				font-weight: 500;
				background: linear-gradient(167deg, #FE8129 0%, #FE0D30 100%) !important;
			}
		}
	}

	.product-item-second {
		position: relative;
		width: 174px;
		// height: 289px;
		border: 0.5px solid #EFEFEF;
		border-radius: 4px;
		margin-bottom: 9px;
		margin-left: 2px;
		margin-right: 3px;

		.img {
			width: 174px;
			height: 174px;

			>img {
				display: block;
				border-radius: 4px 4px 0 0;
				width: 174px;
				height: 174px;
			}

			.kill-icon {
				position: absolute;
				left: 0;
				top: 0;
				width: 55px;
				height: 22px;
			}
		}

		.item-bottom {
			// height: 114px;
			padding: 0 8px;
			display: flex;
			flex-direction: column;

			.name-style {
				font-size: 14px;
				color: #333;
				// height: 20px;
				line-height: 20px;
				margin-top: 2px;
				font-weight: bold;
			}

			.point-style {
				font-size: 12px;
				color: #666;
				// height: 17px;
				line-height: 17px;
			}

			.price {
				flex: 1;
				display: flex;
				align-items: center;

				.price-tag {
					font-size: 10px;
					color: #FE0D30;
					font-weight: 500;
					margin-top: 4px;
				}

				.sale-price {
					font-size: 18px;
					color: #FE0D30;
					font-weight: bold;
				}

				.origin-price {
					font-size: 8px;
					color: #999999;
					text-decoration: line-through;
					margin-left: 5px;
					margin-top: 4px;
				}
			}

			.btn {
				width: 159px;
				margin:3px 0 8px 0;
				border-radius: 2px;
				height: 32px;
				text-align: center;
				line-height: 32px;
				font-size: 16px;
				color: #fff;
				font-weight: bold;
				background: linear-gradient(143deg, #FE8129 0%, #FE0D30 100%);
			}
		}
	}

	.product-item-three {
		position: relative;
		width: 113px;
		// height: 218px;
		border: 0.5px solid #EFEFEF;
		border-radius: 4px;
		margin-bottom: 9px;
		margin-right: 6px;

		.img {
			width: 113px;
			height: 113px;

			>img {
				display: block;
				border-radius: 4px 4px 0 0;
				width: 113px;
				height: 113px;
			}

			.kill-icon {
				position: absolute;
				left: 0;
				top: 0;
				width: 55px;
				height: 22px;
			}
		}

		.item-bottom {
			// height: 104px;
			display: flex;
			flex-direction: column;

			.name-style {
				font-size: 14px;
				color: #333;
				padding: 0 4px;
				line-height: 18px;
				margin-top: 2px;
				font-weight: bold;
			}

			.point-style {
				padding: 0 4px;
				font-size: 12px;
				color: #666;
				height: 17px;
				line-height: 17px;
			}

			.price {
				padding: 0 4px;
				flex: 1;
				display: flex;
				align-items: center;

				.price-tag {
					font-size: 10px;
					color: #FE0D30;
					font-weight: 500;
					margin-top: 4px;
				}

				.sale-price {
					font-size: 18px;
					color: #FE0D30;
					font-weight: bold;
				}

				.origin-price {
					font-size: 8px;
					color: #999999;
					text-decoration: line-through;
					margin-left: 5px;
					margin-top: 4px;
				}
			}

			.btn {
				width: 112px;
				height: 32px;
				text-align: center;
				border-radius: 0 0 4px 4px;
				line-height: 32px;
				font-size: 16px;
				color: #fff;
				font-weight: bold;
				background: linear-gradient(143deg, #FE8129 0%, #FE0D30 100%);
			}
		}
	}

	.product-item-one {
		width: 359px;
		height: 170px;
		display: flex;
		padding: 8px 0;
		align-items: center;
		border-bottom: 0.5px solid #EFEFEF;
		position: relative;

		&:last-child {
			border-bottom: none;
		}

		.img {
			width: 160px;
			height: 160px;
			margin-left: 8px;

			>img {
				display: block;
				border-radius: 4px;
				width: 160px;
				height: 160px;
			}

			.kill-icon {
				position: absolute;
				left: 8px;
				top: 4px;
				width: 55px;
				height: 22px;
			}
		}

		.item-right {
			display: flex;
			flex-direction: column;
			margin: 0 8px;
			height: 160px;
			width: 173px;

			.name-style {
				font-size: 16px;
				color: #333;
				line-height: 22px;
				font-weight: bold;
				margin-top: 10px;
			}

			.point-style {
				flex: 1;
				font-size: 12px;
				color: #666;
				height: 17px;
				line-height: 17px;
				margin-top: 10px;
			}

			.price {
				display: flex;
				justify-content: space-between;

				.price-tag {
					font-size: 10px;
					color: #FE0D30;
					font-weight: 500;
					margin-top: 4px;
				}

				.sale-price {
					font-size: 18px;
					color: #FE0D30;
					margin-left: 4px;
					font-weight: bold;
				}

				.origin-price {
					font-size: 8px;
					color: #999999;
					text-decoration: line-through;
					margin-left: 5px;
					margin-top: 4px;
				}

				.btn {
					height: 32px;
					padding: 0 13px;
					text-align: center;
					border-radius: 4px;
					min-width: 68px;
					line-height: 32px;
					font-size: 16px;
					color: #fff;
					font-weight: bold;
					background: linear-gradient(143deg, #FE8129 0%, #FE0D30 100%);
				}
			}
		}
	}

	.product-item-one-big {
		width: 359px;
		// height: 458px;
		position: relative;
		border-bottom: 0.5px solid #EFEFEF;
		&:last-child {
			border-bottom: none;
		}

		.img {
			width: 359px;
			height: 359px;

			>img {
				display: block;
				border-radius: 4px;
				width: 359px;
				height: 359px;
			}

			.kill-icon {
				position: absolute;
				left: 0;
				top: 0;
				width: 55px;
				height: 22px;
			}
		}

		.item-bottom {
			display: flex;
			flex-direction: column;
			margin: 2px 12px 8px 8px;
			// height: 98px;

			.name-style {
				font-size: 16px;
				color: #333;
				line-height: 25px;
				font-weight: bold;
				margin-top: 4px;
			}

			.point-style {
				flex: 1;
				font-size: 12px;
				color: #666;
				line-height: 20px;
			}

			.price {
				margin-bottom: 8px;
				display: flex;
				justify-content: space-between;

				.price-tag {
					font-size: 10px;
					color: #FE0D30;
					font-weight: 500;
					margin-top: 4px;
				}

				.sale-price {
					font-size: 18px;
					color: #FE0D30;
					margin-left: 4px;
					font-weight: bold;
				}

				.origin-price {
					font-size: 8px;
					color: #999999;
					text-decoration: line-through;
					margin-left: 5px;
					margin-top: 4px;
				}

				.btn {
					height: 32px;
					padding: 0 13px;
					text-align: center;
					border-radius: 4px;
					min-width: 68px;
					line-height: 32px;
					font-size: 16px;
					color: #fff;
					font-weight: bold;
					background: linear-gradient(143deg, #FE8129 0%, #FE0D30 100%);
				}
			}
		}
	}
	
	.tag-name {
		font-size: 14px;
		color: #fff;
		position: absolute;
		top: 0;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: bold;
		width: 55px;
		height: 22px;
		line-height: 22px;
		text-align: center;
	}

	.product-name {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.product-name-second {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2; //要显示的行数
	}
</style>

<template>
	<div class="singleRowProduct-module" @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;"
	:class="{haveSpace: !data.hideSpace}">
		<module-name :name="name"></module-name>
		<controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')"
		@remove="$emit('remove')"></controler>
		<!-- <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  /> -->

		<div style="background: #fff;">
			<!-- @click="edit();data.visible=true" -->

			<div class="tabs" v-if="data.moduleType == 'classify' && data.classifylist.length">
				<div class="tab" :class="{current: index === 0}" v-for="(item, index) in data.classifylist" :key="item.id">
					{{item.groupName}}
				</div>
			</div>
			<div v-if="(data.moduleType == 'shop' && data.shopType == 'classify' || data.moduleType == 'classify') && data.styleType=='tiled'&&(data.countChecked||data.priceChecked||data.newChecked) " style="display: flex;align-items: center;justify-content: center;text-align: center;font-size: 14px;">
				<div style="color: #666;padding-top: 10px;width: 120px;text-align: center;" v-if="data.countChecked">销量</div>
				<div v-if="data.priceChecked" style="color: #333;font-weight: bold;display: flex;align-items: center;padding-top: 10px;width: 120px;text-align: center;justify-content: center;">
					价格
					<div>
						<div style="width: 0; height: 0; border-left: 4px solid transparent; border-right: 4px solid transparent; border-bottom: 4px solid #999; font-size: 0; line-height: 0;"></div>
						<div style="width: 0; height: 0; border-left: 4px solid transparent; border-right: 4px solid transparent; border-top: 4px solid #000; font-size: 0; line-height: 0; margin-top: 3px;"></div>
					</div>
				</div>
				<div v-if="data.newChecked" style="color: #666;padding-top: 10px;width: 120px;text-align: center;">新品</div>
			</div>
			<div class="product-list" v-if="!list.length">
				<div class="tabsScroll">
					<div class="product-item-slide" v-if="data.styleType=='slide'" v-for="i in 4" :key="i">
						<div class="img">
							<img :src="normalData.thumbImgUrl">
							<div class="kill-icon" v-if="data.iconType=='show'">
								<img style="width: 55px;height: 22px;" src="../../../../../assets/img/product-tag-bg.png"></img>
								<div class="tag-name">{{data.iconName||'热卖'}}</div>
							</div>
							<!-- <img v-if="data.iconType=='show'" class="kill-icon" src="../../../../../assets/img/product-tag-bg.png"></img> -->
						</div>
						<div class="right">
							<div class="top">
								<div class="name product-name">{{normalData.goodsName}}</div>
								<div class="point-name" v-if="data.pointShow=='show'">{{normalData.advertisementWords||'此处是卖点'}}</div>
							</div>
							<div class="price" style="align-items: center;margin-bottom: 5px;" :style="{justifyContent:data.priceShow=='show'?'space-between':'flex-end' }">
								<div class="left" v-if="data.priceShow=='show'">
									<span style="font-size: 10px;margin-top: 4px;">&yen;</span>{{normalData.salePrice}}
									<div class="old" style="width: 15px;" v-if="normalData.originalPrice">&yen;{{normalData.originalPrice}}</div>
								</div>
								<img style="width: 20px;height: 20px;" v-if="data.btnType=='2'" src="../../../../../assets/img/gouwuche.png" alt="">
								<img style="width: 20px;height: 20px;" v-if="data.btnType=='3'" src="../../../../../assets/img/jiahao.png" alt="">
							</div>
							<div v-if="data.btnType=='1'" class="btn">{{data.btnText}}</div>
						</div>
					</div>
				</div>
				<div class="product-item-second" v-if="data.styleType=='tiled'&&data.column == '2'" v-for="i in 3" :key="i">
					<div class="img">
						<img :src="normalData.thumbImgUrl">
						<div class="kill-icon" v-if="data.iconType=='show'">
							<img style="width: 55px;height: 22px;" src="../../../../../assets/img/product-tag-bg.png"></img>
							<div class="tag-name">{{data.iconName||'热卖'}}</div>
						</div>
					</div>
					<div class="item-bottom">
						<div class="name-style product-name">{{normalData.goodsName}}</div>
						<div class="point-style product-name" v-if="data.pointShow=='show'">{{normalData.advertisementWords||'此处是卖点'}}</div>
						<div class="price" style="align-items: center;margin-bottom: 5px;" :style="{justifyContent:data.priceShow=='show'?'space-between':'flex-end' }">
							<div style="display: flex;flex-direction: row;" v-if="data.priceShow=='show'">
								<div class="price-tag">&yen;</div>
								<div class="sale-price">{{normalData.salePrice}}</div>
								<div class="origin-price" v-if="normalData.originalPrice">&yen;{{normalData.originalPrice}}</div>
							</div>
							<img style="width: 20px;height: 20px;" v-if="data.btnType=='2'" src="../../../../../assets/img/gouwuche.png" alt="">
							<img style="width: 20px;height: 20px;" v-if="data.btnType=='3'" src="../../../../../assets/img/jiahao.png" alt="">
						</div>
						<div v-if="data.btnType=='1'" class="btn">{{data.btnText}}</div>
					</div>
				</div>
				<div class="product-item-three" v-if="data.styleType=='tiled'&&data.column == '3'" v-for="i in 3" :key="i">
					<div class="img">
						<img :src="normalData.thumbImgUrl">
						<div class="kill-icon" v-if="data.iconType=='show'">
							<img style="width: 55px;height: 22px;" src="../../../../../assets/img/product-tag-bg.png"></img>
							<div class="tag-name">{{data.iconName||'热卖'}}</div>
						</div>
						<!-- <img v-if="data.iconType=='show'" class="kill-icon" src="../../../../../assets/img/product-tag-bg.png"></img> -->
					</div>
					<div class="item-bottom">
						<div class="name-style product-name">{{normalData.goodsName}}</div>
						<div class="point-style product-name" v-if="data.pointShow=='show'">{{normalData.advertisementWords||'此处是卖点'}}</div>
						<div class="price" style="align-items: center;margin-bottom: 5px;" :style="{justifyContent:data.priceShow=='show'?'space-between':'flex-end' }">
							<div style="display: flex;flex-direction: row;" v-if="data.priceShow=='show'">
								<div class="price-tag">&yen;</div>
								<div class="sale-price">{{normalData.salePrice}}</div>
								<div class="origin-price" v-if="normalData.originalPrice">&yen;{{normalData.originalPrice}}</div>
							</div>
							<img style="width: 20px;height: 20px;" v-if="data.btnType=='2'" src="../../../../../assets/img/gouwuche.png" alt="">
							<img style="width: 20px;height: 20px;" v-if="data.btnType=='3'" src="../../../../../assets/img/jiahao.png" alt="">
						</div>
						<div v-if="data.btnType=='1'" class="btn">{{data.btnText}}</div>
					</div>
				</div>
				<div style="border: 0.5px solid #EFEFEF;border-radius: 4px;" v-if="data.styleType=='tiled'&&data.column == '1' &&data.imageType=='1'">
					<div class="product-item-one" v-for="i in 3" :key="i">
						<div class="img">
							<img :src="normalData.thumbImgUrl">
							<div class="kill-icon" v-if="data.iconType=='show'">
								<img style="width: 55px;height: 22px;" src="../../../../../assets/img/product-tag-bg.png"></img>
								<div class="tag-name">{{data.iconName||'热卖'}}</div>
							</div>
							<!-- <img v-if="data.iconType=='show'" class="kill-icon" src="../../../../../assets/img/product-tag-bg.png"></img> -->
						</div>
						<div class="item-right">
							<div class="name-style product-name">{{normalData.goodsName}}</div>
							<div class="point-style product-name" v-if="data.pointShow=='show'">{{normalData.advertisementWords||'此处是卖点'}}</div>
							<div class="price">
								<div v-if="data.priceShow=='show'">
									<div v-if="data.btnText.length>3">
										<div style="display: flex;align-items: center;">
											<div class="price-tag" style="display: table-cell;vertical-align: bottom;">&yen;</div>
											<div class="sale-price">{{normalData.salePrice}}</div><strong></strong>
										</div>
										<div class="origin-price" style="display: table-cell;vertical-align: bottom;margin-top: 4px;" v-if="normalData.originalPrice">&yen;{{normalData.originalPrice}}</div>
									</div>
									<div v-else style="display: flex;align-items: center;">
										<div class="price-tag" style="display: table-cell;vertical-align: bottom;">&yen;</div>
										<div class="sale-price">{{normalData.salePrice}}</div>
										<div class="origin-price" style="display: table-cell;vertical-align: bottom;" v-if="normalData.originalPrice">&yen;{{normalData.originalPrice}}</div>
									</div>
								</div>
								<div v-else style="color: #fff;">.</div>
								<div>
									<div v-if="data.btnType=='1'" class="btn">{{data.btnText}}</div>
									<img style="width: 20px;height: 20px;" v-if="data.btnType=='2'" src="../../../../../assets/img/gouwuche.png" alt="">
									<img style="width: 20px;height: 20px;" v-if="data.btnType=='3'" src="../../../../../assets/img/jiahao.png" alt="">
								</div>
								
							</div>
						</div>
					</div>
				</div>
				<div style="border: 0.5px solid #EFEFEF;border-radius: 4px;" v-if="data.styleType=='tiled'&&data.column == '1'&&data.imageType=='2'">
					<div class="product-item-one-big" v-for="i in 3" :key="i">
						<div class="img">
							<img :src="normalData.thumbImgUrl">
							<div class="kill-icon" v-if="data.iconType=='show'">
								<img style="width: 55px;height: 22px;" src="../../../../../assets/img/product-tag-bg.png"></img>
								<div class="tag-name">{{data.iconName||'热卖'}}</div>
							</div>
							<!-- <img v-if="data.iconType=='show'" class="kill-icon" src="../../../../../assets/img/product-tag-bg.png"></img> -->
						</div>
						<div class="item-bottom">
							<div class="name-style" :class="data.pointShow == 'show' ? 'product-name' : 'product-name-second'">{{normalData.goodsName}}</div>
							<div class="point-style product-name" v-if="data.pointShow=='show'">{{normalData.advertisementWords||'此处是卖点'}}</div>
							<div class="price">
								<div style="display: flex;align-items: center;" v-if="data.priceShow=='show'">
									<div class="price-tag" style="display: table-cell;vertical-align: bottom;">&yen;</div>
									<div class="sale-price">{{normalData.salePrice}}</div>
									<div class="origin-price" style="display: table-cell;vertical-align: bottom;" v-if="normalData.originalPrice">&yen;{{normalData.originalPrice}}</div>
								</div>
								<div v-if="data.priceShow=='hide'" style="color: #fff;">1</div>
								<div>
									<div v-if="data.btnType=='1'" class="btn">{{data.btnText}}</div>
									<img style="width: 20px;height: 20px;" v-if="data.btnType=='2'" src="../../../../../assets/img/gouwuche.png" alt="">
									<img style="width: 20px;height: 20px;" v-if="data.btnType=='3'" src="../../../../../assets/img/jiahao.png" alt="">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="product-list" :class="{listSlide: data.styleType=='slide'}" v-else>
				<div class="tabsScroll">
					<div class="product-item-slide" v-if="data.styleType=='slide'" v-for="(item,index) in list" :key="index">
						<div class="img">
							<img :src="item.thumbImgUrl">
							<div class="kill-icon" v-if="data.iconType=='show'">
								<img style="width: 55px;height: 22px;" src="../../../../../assets/img/product-tag-bg.png"></img>
								<div class="tag-name">{{data.iconName||'热卖'}}</div>
							</div>
							<!-- <img v-if="data.iconType=='show'" class="kill-icon" src="../../../../../assets/img/product-tag-bg.png"></img> -->
						</div>
						<div class="right">
							<div class="top">
								<div class="name" :class="data.pointShow == 'show' ? 'product-name' : 'product-name-second'">{{item.goodsName}}</div>
								<div class="point-name" v-if="data.pointShow=='show'">{{item.advertisementWords||'此处是卖点'}}</div>
							</div>
							<div class="price" style="align-items: center;margin-bottom: 5px;" :style="{justifyContent:data.priceShow=='show'?'space-between':'flex-end' }">
								<div class="left" v-if="data.priceShow=='show'">
									<span style="font-size: 10px;margin-top: 4px;">&yen;</span>{{item.salePrice}}
									<div class="old" style="width: 15px;" v-if="item.originalPrice">&yen;{{item.originalPrice}}</div>
								</div>
								<img style="width: 20px;height: 20px;" v-if="data.btnType=='2'" src="../../../../../assets/img/gouwuche.png" alt="">
								<img style="width: 20px;height: 20px;" v-if="data.btnType=='3'" src="../../../../../assets/img/jiahao.png" alt="">
							</div>
							<div v-if="data.btnType=='1'" class="btn">{{data.btnText}}</div>
						</div>
					</div>
				</div>
				<div class="product-item-second" v-if="data.styleType=='tiled'&&data.column == '2'" v-for="(item,index) in list"
				:key="index">
					<div class="img">
						<img :src="item.thumbImgUrl">
						<div class="kill-icon" v-if="data.iconType=='show'">
							<img style="width: 55px;height: 22px;" src="../../../../../assets/img/product-tag-bg.png"></img>
							<div class="tag-name">{{data.iconName||'热卖'}}</div>
						</div>
						<!-- <img v-if="data.iconType=='show'" class="kill-icon" src="../../../../../assets/img/product-tag-bg.png"></img> -->
					</div>
					<div class="item-bottom">
						<div class="name-style product-name">{{item.goodsName}}</div>
						<div class="point-style product-name" v-if="data.pointShow=='show'">{{item.advertisementWords||'此处是卖点'}}</div>
						<div class="price" style="align-items: center;margin-bottom: 5px;" :style="{justifyContent:data.priceShow=='show'?'space-between':'flex-end' }">
							<div style="display: flex;flex-direction: row;" v-if="data.priceShow=='show'">
									<div class="price-tag">&yen;</div>
									<div class="sale-price">{{normalData.salePrice}}</div>
									<div class="origin-price" v-if="normalData.originalPrice">&yen;{{normalData.originalPrice}}</div>
								</div>
								<img style="width: 20px;height: 20px;" v-if="data.btnType=='2'" src="../../../../../assets/img/gouwuche.png" alt="">
								<img style="width: 20px;height: 20px;" v-if="data.btnType=='3'" src="../../../../../assets/img/jiahao.png" alt="">
							</div>
							<div v-if="data.btnType=='1'" class="btn">{{data.btnText}}</div>
					</div>
				</div>
				<div class="product-item-three" v-if="data.styleType=='tiled'&&data.column == '3'" v-for="(item,index) in list"
				:key="index">
					<div class="img">
						<img :src="item.thumbImgUrl">
						<div class="kill-icon" v-if="data.iconType=='show'">
							<img style="width: 55px;height: 22px;" src="../../../../../assets/img/product-tag-bg.png"></img>
							<div class="tag-name">{{data.iconName||'热卖'}}</div>
						</div>
						<!-- <img v-if="data.iconType=='show'" class="kill-icon" src="../../../../../assets/img/product-tag-bg.png"></img> -->
					</div>
					<div class="item-bottom">
						<div class="name-style product-name">{{item.goodsName}}</div>
						<div class="point-style product-name" v-if="data.pointShow=='show'">{{item.advertisementWords||'此处是卖点'}}</div>
						<div class="price" style="align-items: center;margin-bottom: 5px;" :style="{justifyContent:data.priceShow=='show'?'space-between':'flex-end' }">
							<div style="display: flex;flex-direction: row;" v-if="data.priceShow=='show'">
								<div class="price-tag">&yen;</div>
								<div class="sale-price">{{item.salePrice}}</div>
								<div class="origin-price" v-if="item.originalPrice">&yen;{{item.originalPrice}}</div>
							</div>
							<img style="width: 20px;height: 20px;" v-if="data.btnType=='2'" src="../../../../../assets/img/gouwuche.png" alt="">
							<img style="width: 20px;height: 20px;" v-if="data.btnType=='3'" src="../../../../../assets/img/jiahao.png" alt="">
						</div>
						<div v-if="data.btnType=='1'" class="btn">{{data.btnText}}</div>
					</div>
				</div>
				<div style="border: 0.5px solid #EFEFEF;border-radius: 4px;" v-if="data.styleType=='tiled'&&data.column == '1' &&data.imageType=='1'">
					<div class="product-item-one" v-for="(item,index) in list" :key="index">
						<div class="img">
							<img :src="item.thumbImgUrl">
							<div class="kill-icon" v-if="data.iconType=='show'">
								<img style="width: 55px;height: 22px;" src="../../../../../assets/img/product-tag-bg.png"></img>
								<div class="tag-name">{{data.iconName||'热卖'}}</div>
							</div>
							<!-- <img v-if="data.iconType=='show'" class="kill-icon" src="../../../../../assets/img/product-tag-bg.png"></img> -->
						</div>
						<div class="item-right">
							<div class="name-style product-name">{{item.goodsName}}</div>
							<div class="point-style product-name" v-if="data.pointShow=='show'">{{item.advertisementWords||'此处是卖点'}}</div>
							<div class="price">
								<div v-if="data.priceShow=='show'">
									<div v-if="data.btnText.length>3">
										<div style="display: flex;align-items: center;">
											<div class="price-tag" style="display: table-cell;vertical-align: bottom;">&yen;</div>
											<div class="sale-price">{{item.salePrice}}</div><strong></strong>
										</div>
										<div class="origin-price" style="display: table-cell;vertical-align: bottom;margin-top: 4px;" v-if="item.originalPrice">&yen;{{item.originalPrice}}</div>
									</div>
									<div v-else style="display: flex;align-items: center;">
										<div class="price-tag" style="display: table-cell;vertical-align: bottom;">&yen;</div>
										<div class="sale-price">{{item.salePrice}}</div>
										<div class="origin-price" style="display: table-cell;vertical-align: bottom;" v-if="item.originalPrice">&yen;{{item.originalPrice}}</div>
									</div>
								</div>
								<div v-else style="color: #fff;">.</div>
								<div>
									<div v-if="data.btnType=='1'" class="btn">{{data.btnText}}</div>
									<img style="width: 20px;height: 20px;" v-if="data.btnType=='2'" src="../../../../../assets/img/gouwuche.png" alt="">
									<img style="width: 20px;height: 20px;" v-if="data.btnType=='3'" src="../../../../../assets/img/jiahao.png" alt="">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div style="border: 0.5px solid #EFEFEF;border-radius: 4px;" v-if="data.styleType=='tiled'&&data.column == '1'&&data.imageType=='2'">
					<div class="product-item-one-big" v-for="(item,index) in list" :key="index">
						<div class="img">
							<img :src="item.thumbImgUrl">
							<div class="kill-icon" v-if="data.iconType=='show'">
								<img style="width: 55px;height: 22px;" src="../../../../../assets/img/product-tag-bg.png"></img>
								<div class="tag-name">{{data.iconName||'热卖'}}</div>
							</div>
							<!-- <img v-if="data.iconType=='show'" class="kill-icon" src="../../../../../assets/img/product-tag-bg.png"></img> -->
						</div>
						<div class="item-bottom">
							<div class="name-style" :class="data.pointShow == 'show' ? 'product-name' : 'product-name-second'">{{item.goodsName}}</div>
							<div class="point-style product-name" v-if="data.pointShow=='show'">{{item.advertisementWords||'此处是卖点'}}</div>
							<div class="price">
								<div style="display: flex;align-items: center;" v-if="data.priceShow=='show'">
									<div class="price-tag" style="display: table-cell;vertical-align: bottom;">&yen;</div>
									<div class="sale-price">{{item.salePrice}}</div>
									<div class="origin-price" style="display: table-cell;vertical-align: bottom;" v-if="item.originalPrice">&yen;{{item.originalPrice}}</div>
								</div>
								<div v-if="data.priceShow=='hide'" style="color: #fff;">1</div>
								<div>
									<div v-if="data.btnType=='1'" class="btn">{{data.btnText}}</div>
									<img style="width: 20px;height: 20px;" v-if="data.btnType=='2'" src="../../../../../assets/img/gouwuche.png" alt="">
									<img style="width: 20px;height: 20px;" v-if="data.btnType=='3'" src="../../../../../assets/img/jiahao.png" alt="">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>


	</div>
</template>

<script>
	import moduleName from '../../moduleName';
	import controler from '../../controler';
	import edit from './edit';

	export default {
		name: 'normal-module',
		components: {
			moduleName,
			controler,
			edit,

		},
		computed: {
			list() {
				console.log("----classify---", this.data)
				if (this.data.moduleType == 'shop' && this.data.shopType == 'shop') {
					return this.data.list;
				} else if (this.data.moduleType == 'shop' && this.data.shopType == 'classify') {
					if (this.data.shopClassifylist.length) {
						return this.data.shopClassifylist[0].goodsList;
					} else {
						return []
					}
				} else {
					console.log(this.data.classifylist)
					if (this.data.classifylist.length) {
						return this.data.classifylist[0].goodsList;
					} else {
						return []
					}
				}
			},
		},
		props: {
			name: {
				type: String,
				default: ''
			},
			data: {
				type: Object,
				default: () => {
					return {}
				}
			},

		},
		data() {
			return {
				selectedIndex: 0,
				normalData: {
					thumbImgUrl: this.$store.state.pageManageConfig.staticImage.noPic,
					goodsName: '此处是商品名称-此处是商品名称',
					SellPoint: '此处是卖点',
					salePrice: 99.00,
					originalPrice: 100,
				}
			};
		},
		methods: {
			edit() {
				this.$emit('visibleChange', this.data.visible)
			},

			close() {
				this.$emit('visibleChange', false)
			}

		},
		mounted() {
			console.log("----product-----", this.data)
		},
		watch: {

		}
	};
</script>
