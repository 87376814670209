<style scoped lang="less">
	.search-box{
        margin-bottom: 20px;
    }
    .table-box{
        margin-bottom: 15px;
    }
	
	.shop-name{
		display: flex;
	}
	.shop-name .img{
		flex: 0 0 auto;
	}
	.shop-name .name{
		flex: 1 1 auto;
		overflow: hidden;
		display: flex;
		align-items: center;
	}
</style>
<template>
	<el-dialog :visible="show" width='990px' title="选择限时折扣" @close="cancel">

		<div class="search-box">
			<span style="padding-right: 10px;">关键字：</span>
			<el-input v-model="keywords" style="width:200px;margin-right:30px" size="mini" placeholder="活动名称" />

			<span style="padding-right: 10px;">活动状态：</span>
			<el-select v-model="state" placeholder="请选择" style="width:200px;margin-right:10px">
				<el-option label="全部" :value="null"></el-option>
				<el-option label="未开始" :value="0"></el-option>
				<el-option label="秒杀中" :value="1"></el-option>
				<el-option label="已结束" :value="2"></el-option>
			</el-select>
			<el-button type="primary" size="mini" @click="search">搜索</el-button>
		</div>

		<div class="table-box" v-if="show">
			<el-table :data="data" ref="tab" v-loading="loading" @row-click="handleRowClick" size="mini" row-key="getRowKey">
				<el-table-column width="40px">
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.checked"></el-checkbox>
					</template>
				</el-table-column>
				<el-table-column prop="activityName" label="活动名称" width="400px"></el-table-column>
				<el-table-column label="活动时间" width="400px">
					<template slot-scope="scope">
						{{scope.row.startTime}}至{{scope.row.endTime}}
					</template>
				</el-table-column>
				<el-table-column label="活动状态" width="100px">
					<template slot-scope="scope">
						<span v-if="scope.row.status === 0">未开始</span>
						<span v-if="scope.row.status === 1">进行中</span>
						<span v-if="scope.row.status === 2">已结束</span>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<el-pagination :current-page="pageIndex" :total="total" :page-size="pageSize" @current-change="pageChange"></el-pagination>

		<div slot="footer">
			<el-button type="primary" @click="btnSure">确定</el-button>
		</div>

	</el-dialog>
</template>
<script>
	import {
		seckillList,
		seckillDetail,
	} from '@/api/goods'
	import config from '@/config/index'
	export default {
		components: {},
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			moduleType: String,
			selected: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		data() {
			return {
				keywords: '',
				pageIndex: 1,
				pageSize: 10,
				total: 0,
				loading: false,
				selectedData: [],
				singleData: {},
				data: [],
				show: false,
				imgUrl: config.IMG_BASE,
				state: null
			};
		},
		methods: {
			async getData() {
				this.loading = true
				try {
					const params = {
						activityName: this.keywords, //活动名称
						status: this.state,
						startTime: '',
						endTime: '',
						pageNo: this.pageIndex,
						pageSize: this.pageSize
					}
					let result = await seckillList(params);
					result.data.records.map(item => {
						item.activityStatus = item.status == 0 ? '活动未开始' : item.status == 1 ? '秒杀中' : '活动已结束';
						return item;
					})
					this.data = result.data.records;
					this.total = result.data.total;
				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}

				if (!this.isReady) {
					if (this.$refs['tab']) {
						console.log(this.selected, 'this.selectedList')
						// this.selectedList.map(item => {
						this.$refs['tab'].toggleRowSelection(this.selected, true);
						// })
						this.isReady = true;
					}
				}

			},
			async getDetail(){
				let data = {
					secKillId:this.selectedData[0].secKillId
				}
				let result = await seckillDetail(data);
				if(result.success){
					this.detailData = result.data;
					this.selectedData[0].list = result.data.goodsList;
					this.$emit('change', this.selectedData);
					this.cancel();
				}else{
					this.$message({
						showClose:true,
						type:'error',
						message:result.alertMsg
					})
				}
			},
			getRowKey(row) {
				return row.secKillId
			},
			cancel() {
				this.$emit('cancel');
			},
			pageChange(value) {
				this.pageIndex = value;
				this.getData();
			},
			search() {
				this.pageIndex = 1;
				this.getData();
			},
			handleRowClick(row) {
				this.data = this.data.map(item => {
					console.log(item.secKillId == row.secKillId)
					item.checked = item.secKillId == row.secKillId;
					return item;
				})
				row.checked = true;
				console.log(row)
				this.selectedData = [row]
			},
			btnSure() {
				// if (this.isSingle && this.selectedData.length > this.disableList.length+1){
				//     this.$Message.error('修改产品只能单选！');
				//     return;
				// }
				this.getDetail();
				// this.$emit('change', this.selectedData);
				// this.cancel();
			}
		},
		watch: {
			visible(value) {
				this.show = value;

				if (value) {
					this.getData();
				}
			}
		},
		mounted() {
			this.getData();
		},
		created() {

		},
		dispatch() {

		}
	};
</script>
