<style scoped lang="less">

    .singleRowProduct-module{
        
        .product-list{
        	
        	overflow: hidden;
        	padding: 10px 8px 5px;
        	background: #fff;
        	
            .product-item{
        		position: relative;
                float: left;
        		width: 176px;
        		height: 275px;
                background: #fff;
                
                margin-right: 5px;
        		margin-bottom: 10px;
        		box-shadow: 0 3px 2px 0px rgba(99, 99, 99, 0.1);
        		border-radius: 6px;
        
                &:nth-child(2n){
                    margin-right: 0;
                }
        
                .img{
                    width: 176px;
                    height: 176px;
        			border-radius: 6px 6px 0 0;
					overflow: hidden;
					
					img{
						display: block;
						width: 100%;
					}
                }
        
                .name{
                    margin: 8px 5px 10px;
                    font-size: 12px;
                    line-height: 16px;
                    height: 32px;
        
                    display: -webkit-box; 
                    -webkit-box-orient:vertical; 
                    -webkit-line-clamp: 2; 
                    overflow: hidden;
                }
				
				.stock{
					font-size: 12px;
					color: #999;
					padding: 0 5px;
				}
                
                .pro-info{
                    display: flex;
                    margin: 0 5px;
        			align-items: center;
        
                    .left{
                        flex: 1 1 auto;
                        color: #ff547b;
                        font-weight: bold;
                        font-size: 14px;
                    }
        			
        			.right{
        				flex: 0 0 auto;
        				width: 61px;
        				height: 25px;
						line-height: 25px;
						text-align: center;
						font-size: 13px;
						background: #ff547b;
						color: #fff;
						border-radius: 12px;
        			}
                }

            }
            
        }
		
		
		.coupon-list{
			background: #fff;
			padding: 10px;
			.item{
				display: flex;
				margin-bottom: 5px;
				box-shadow: 0 3px 2px 0px rgba(99, 99, 99, 0.1);
				
				&:last-child{
					margin-bottom: 0;
				}
				
				img{
					flex: 0 0 auto;
					display: block;
					width: 125px;
					height: 108px;
					margin-right: 10px;
				}
				
				.info{
					flex: 1 1 auto;
					overflow: hidden;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					padding-right: 10px;
					padding-bottom: 10px;
					
					.name{
					    margin: 8px 5px 10px;
					    font-size: 12px;
					    line-height: 16px;
					    height: 32px;
					        
					    display: -webkit-box; 
					    -webkit-box-orient:vertical; 
					    -webkit-line-clamp: 2; 
					    overflow: hidden;
					}
					
					.stock{
						font-size: 12px;
						color: #999;
						padding: 0 5px;
					}
					
					.pro-info{
					    display: flex;
					    margin: 0 5px;
						align-items: center;
					        
					    .left{
					        flex: 1 1 auto;
					        color: #ff547b;
					        font-weight: bold;
					        font-size: 14px;
					    }
						
						.right{
							flex: 0 0 auto;
							width: 61px;
							height: 25px;
							line-height: 25px;
							text-align: center;
							font-size: 13px;
							background: #ff547b;
							color: #fff;
							border-radius: 12px;
						}
					}
				}
			}
		}
        
    }
    .haveSpace{
        margin-bottom: 10px;
    }
    
</style>

<template>
    <div class="singleRowProduct-module" @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;" :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        <!-- <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  /> -->
        
		<div> <!-- @click="edit();data.visible=true" -->
			<div v-if="data.moduleType == 1">
				<div class="product-list" v-if="!data.list.length">
				    <div class="product-item" :key="item" v-for="item in 2">
						<div class="img">
							<img src="https://api.static.huibogoulive.cn/images/dyj/no-pic.png" />
						</div>
				        
				        <div class="name">此处是商品名称-此处是商品名称</div>
						<div class="stock">剩余999件</div>
				        <div class="pro-info">
				            <div class="left">
				                888积分
				            </div>
				        	<div class="right">
				        		兑换
				        	</div>
				        </div>
				    </div>
				</div>
				<div class="product-list" v-else>
				    <div class="product-item" :key="item.ActivityFullId" v-for="item in data.list">
						<div class="img">
							<img :src="$store.state.pageManageConfig.imghost+item.ImgUrl" />
						</div>
				        
				        <div class="name">{{item.Name}}</div>
						<div class="stock">剩余{{item.RemainCount}}件</div>
				        <div class="pro-info">
				            <div class="left">
				                {{item.PointCount}}积分<span v-if="item.PointPrice">+{{item.PointPrice}}元</span>
				            </div>
				        	<div class="right">
				        		兑换
				        	</div>
				        </div>
				    </div>
				</div>
			</div>
			<div v-if="data.moduleType == 2">
				<div class="coupon-list" v-if="!data.couponList.length">
					<div class="item">
						<img src="https://api.static.huibogoulive.cn/images/melyshop/page-manage-point-img.png" />
						<div class="info">
							<div class="name">此处是优惠券名称此处是优惠券名称此处是优惠券名称此处是优惠券名称</div>
							<div class="stock">剩余999件</div>
							<div class="pro-info">
							    <div class="left">
							        888积分
							    </div>
								<div class="right">
									兑换
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="coupon-list" v-else>
					<div class="item" :key="item.ActivityFullId" v-for="item in data.couponList" >
						<img src="https://api.static.huibogoulive.cn/images/melyshop/page-manage-point-img.png" />
						<div class="info">
							<div class="name">{{item.Name}}</div>
							<div class="stock">剩余{{item.RemainCount}}件</div>
							<div class="pro-info">
							    <div class="left">
							        {{item.PointCount}}积分<span v-if="item.PointPrice">+{{item.PointPrice}}元</span>
							    </div>
								<div class="right">
									兑换
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
        
		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>
		

    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
// import edit from './edit';

export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        // edit
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
		// console.log(this.data)
    },
    watch: {
        data(value){
            // console.log(value)
        }
    }
};
</script>

