<style scoped lang="less">
	.search-box{
        margin-bottom: 20px;
    }
    .table-box{
        margin-bottom: 15px;
    }
	
	.shop-name{
		display: flex;
	}
	.shop-name .img{
		flex: 0 0 auto;
	}
	.shop-name .name{
		flex: 1 1 auto;
		overflow: hidden;
		display: flex;
		align-items: center;
	}
</style>
<template>
	<el-dialog :visible="show" :append-to-body="true" width='990px' title="选择商品" @close="cancel">

		<div class="search-box filter-container">
			<div class="filter-item">
				<span style="padding-right: 10px;">关键字：</span>
				<el-input v-model="keywords" style="width:200px;margin-right:10px" size="mini" placeholder="商品名称、编码" />
			</div>
			<div class="filter-item">
				<span style="padding-right: 10px;">销售类型: </span>
				<el-select v-model="saleType" style="width: 160px;margin-right: 10px;" clearable size="mini">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in saleSelect" :key="item.id" :label="item.type" :value="item.id">
					</el-option>
				</el-select>
			</div>

			<div class="filter-item">
				<span style="padding-right: 10px;">商品分组: </span>
				<el-select v-model="goodsType" size="mini" placeholder="请选择" style="width: 160px;margin-right: 10px;" clearable
				 filterable>
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in productSortList" :key="item.Value" :label="item.Text" :value="item.Value">
					</el-option>
				</el-select>
			</div>
			
			<div class="filter-item">
				<span style="padding-right: 10px;">商品品牌: </span>
				<el-select v-model="brandType" size="mini" placeholder="请选择" style="width: 160px;" clearable filterable>
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in productList" :key="item.Value" :label="item.Text" :value="item.Value">
					</el-option>
				</el-select>
			</div>

			<div class="filter-item">
				<el-button type="primary" size="mini" @click="search">搜索</el-button>
			</div>
		</div>

		<div class="table-box">
			<el-table :data="data" v-loading="loading" size="mini" :row-key="getRowKey" ref="tab" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
				<el-table-column label="商品" width="415px">
					<template slot-scope="scope">
						<div class="shop-name" style="overflow: hidden;">
							<div class="img" style="width: 66px;height: 66px;">
								<img :src="imgUrl + scope.row.ImgUrl" style="display: block;width: 100%;" />
							</div>
							<div class="name" style="margin-left: 10px;">
								<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="销售类型" prop="ProductTypeDisplay" width="160px">
					<!--            		<template slot-scope="scope">
            			<span v-if="scope.row.ProductType===0">普通商品</span>
            			<span v-else>组合商品</span>
            		</template> -->
				</el-table-column>
				<el-table-column label="价格" width="160px">
					<template slot-scope="scope">
						&yen;{{scope.row.ProductPrice}}
					</template>
				</el-table-column>
				<el-table-column prop="Stock" label="库存" width="160px"></el-table-column>
			</el-table>
		</div>

		<el-pagination :current-page="pageIndex" :total="total" :page-size="pageSize" @current-change="pageChange"></el-pagination>

		<div slot="footer">
			<el-button type="primary" @click="btnSure">确定</el-button>
		</div>

	</el-dialog>
</template>
<script>
	import {
		editSelectProduct
	} from '../../api/pageManage.js';
	import {
		editSelectinit
	} from '@/api/goods';
	import config from '@/config/index'
	export default {
		components: {

		},
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			isSingle: { //是否是单选
				type: Boolean,
				default: false
			},
			selectedList: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		data() {
			return {
				goodsType:null,
				productSortList: [],
				brandType:null,
				productList: [],
				saleType:null, //销售类型
				saleSelect: [{
						id: 0,
						type: '单品销售'
					},
					{
						id: 1,
						type: '组合销售'
					},
				],
				keywords: '',
				pageIndex: 1,
				pageSize: 10,
				total: 0,
				loading: false,
				selectedData: [],
				singleData: {},
				data: [],
				show: false,
				imgUrl: config.IMG_BASE
			};
		},
		methods: {
			//筛选数据
			async getInfor() {
				try {
					let result = await editSelectinit()
					// 品牌
					this.productList = result.Result.ProductBrandSelectList
					this.productSortList = result.Result.ProductGroupSelectList
					

				} catch (e) {
					console.log(e)
				} finally {

				}

			},
			async getData() {
				const params = {
					Keywords: this.keywords,
					ProductType:this.saleType,
					ProductGroupId:this.goodsType,
					ProductBrandId:this.brandType,
					Skip: (this.pageIndex - 1) * this.pageSize,
					Take: this.pageSize
				}

				this.loading = true;

				const result = await editSelectProduct(params);

				this.data = result.Result.Results;
				this.total = result.Result.Total;
				this.loading = false;

				if (!this.isReady) {
					if (this.$refs['tab']) {
						this.selectedList.map(item => {
							this.$refs['tab'].toggleRowSelection(item, true);
						})
						this.isReady = true;
					}
				}

			},
			getRowKey(row) {
				return row.Id
			},
			cancel() {
				this.$emit('cancel');
			},
			pageChange(value) {
				this.pageIndex = value;
				this.getData();
			},
			search() {
				this.pageIndex = 1;
				this.getData();
			},
			//当前页全选
			handleSelectionChange(val) {
				this.selectedData = val;
			},
			selectedRow(record, row) {
				this.singleData = row;
			},
			btnSure() {
				// if (this.isSingle && this.selectedData.length > this.disableList.length+1){
				//     this.$Message.error('修改产品只能单选！');
				//     return;
				// }

				this.$emit('change', this.selectedData);
				this.cancel();
			}
		},
		watch: {
			visible(value) {
				this.show = value;

				if (value) {
					if (this.$refs['tab']) {
						this.$refs['tab'].clearSelection()
						this.selectedList.map(item => {
							this.$refs['tab'].toggleRowSelection(item, true);
						})
					}
					// this.saleType = null
					// this.goodsType = null
					// this.brandType =null
					this.getInfor()
					this.getData();

				}
			}
		},
		mounted() {
			// this.selectedList.map(item => {
			// 	this.$refs['tab'].toggleRowSelection(item, true);
			// })
			//this.getData();
		},
		created() {

		},
		dispatch() {

		}
	};
</script>
