<style scoped lang="less">
    .singleRowProduct-module{
        
        .product-list{
            .product-item{
                
                background: #fff;
                padding-bottom: 5px;
                margin-bottom: 10px;

                &:last-child{
                    margin-bottom: 0;
                }

                .name{
                    margin: 10px 10px 5px;
                    font-size: 14px;
                    
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

            }
            
        }
        .video-box{
            position: relative;

            .coverImage{
                position: absolute;
                left:0;
                top:0;
                width: 100%;
                height: 100%;
            }
        }
        
    }
    .haveSpace{
        margin-bottom: 20px;
    }
</style>

<template>
    <div class="singleRowProduct-module"  :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=!data.editVisible" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  />
        
        <div @click="edit();data.visible=true">
            <div class="product-list" v-if="data.videoUrl===''">
                <div class="product-item">
                    <img :src="normalData.url" />
                    <p class="name">{{normalData.name}}</p>
                </div>
            </div>
            <div class="product-list" v-else>
                <div class="product-item">
                    <div class="video-box">
                        <video width="100%" style="vertical-align:top" :src="$store.state.pageManageConfig.imghost+data.videoUrl" controls="controls">
                        您的浏览器不支持 video 标签。
                        </video>
                        <img class="coverImage" v-if="data.coverImage!==''" :src="$store.state.pageManageConfig.imghost+data.coverImage" />
                    </div>
                    <p class="name">{{data.videoTitle}}</p>
                </div>
            </div>
        </div>


    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';



export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit,
        
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
            normalData: {
                url: this.$store.state.pageManageConfig.staticImage.noVideo,
                name: '此处是视频标题-此处是视频标题',
            }
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
    },
    watch: {
        
    }
};
</script>

