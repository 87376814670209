<style scoped lang="less">
	.search-box{
        margin-bottom: 20px;
    }
    .table-box{
        margin-bottom: 15px;
    }
	
	.shop-name{
		display: flex;
	}
	.shop-name .img{
		flex: 0 0 auto;
	}
	.shop-name .name{
		flex: 1 1 auto;
		overflow: hidden;
		display: flex;
		align-items: center;
	}
</style>
<template>
	<el-dialog :visible="show" width='990px' title="选择分组" @close="cancel">

		<!-- <div class="search-box">
			<span style="padding-right: 10px;">关键字：</span>
		    <el-input v-model="keywords" style="width:200px;margin-right:10px" size="mini" placeholder="商品名称、编码"/>
		    <el-button type="primary" size="mini" @click="search">搜索</el-button>
		</div> -->

		<div class="table-box" v-if="show">
			<el-table :data="data" v-loading="loading" @row-click="handleRowClick" row-key="getRowKey">
				<el-table-column width="40px">
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.checked"></el-checkbox>
					</template>
				</el-table-column>
				<el-table-column prop="groupName" label="分组名称" width="700px"></el-table-column>
				<el-table-column prop="goodsCount" label="关联商品数量" width="200px"></el-table-column>
			</el-table>
		</div>

		<el-pagination :current-page="pageIndex" :total="total" :page-size="pageSize" @current-change="pageChange"></el-pagination>

		<div slot="footer">
			<el-button type="primary" @click="btnSure">确定</el-button>
		</div>

	</el-dialog>
</template>
<script>
	import {
		getGoodsListByGroupId,
		productGroupListData,
	} from '@/api/goods'
	import config from '@/config/index'
	export default {
		components: {},
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			moduleType: String,
			selectedList: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		data() {
			return {
				keywords: '',
				pageIndex: 1,
				pageSize: 10,
				total: 0,
				loading: false,
				selectedData: [],
				singleData: {},
				data: [],
				show: false,
				imgUrl: config.IMG_BASE
			};
		},
		methods: {
			async getData() {
				const params = {
					// Keywords: this.keywords,
					Skip: (this.pageIndex - 1) * this.pageSize,
					Take: this.pageSize
				}

				this.loading = true;

				const result = await productGroupListData(params);
				this.data = result.data.records;
				this.total = result.data.total;
				this.loading = false;

				if (!this.isReady) {
					if (this.$refs['tab']) {
						this.selectedList.map(item => {
							this.$refs['tab'].toggleRowSelection(item, true);
						})
						this.isReady = true;
					}
				}

			},
			// 获取分组商品
			async getGroupGoods (param) {
				try {
					let data = {
						id: param[0].groupId
					}
					let result = await getGoodsListByGroupId(data);
					this.selectedData[0].goodsList = result.data || [];
					this.$emit('change', this.selectedData);
					this.cancel();
				} catch (e) {
					console.log(e)
				}
			},
			getRowKey(row) {
				return row.groupId
			},
			cancel() {
				this.$emit('cancel');
			},
			pageChange(value) {
				this.pageIndex = value;
				this.getData();
			},
			search() {
				this.pageIndex = 1;
				this.getData();
			},
			handleRowClick(row) {
				this.data = this.data.map(item => {
					item.checked = item.groupId == row.groupId;
					return item;
				})
				this.selectedData = [row]
			},
			btnSure() {
				// if (this.isSingle && this.selectedData.length > this.disableList.length+1){
				//     this.$Message.error('修改产品只能单选！');
				//     return;
				// }
				this.getGroupGoods(this.selectedData);
			}
		},
		watch: {
			visible(value) {
				this.show = value;

				if (value) {
					this.getData();
				}
			}
		},
		mounted() {
			this.getData();
		},
		created() {

		},
		dispatch() {

		}
	};
</script>
