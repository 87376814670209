import { render, staticRenderFns } from "./classifySingleModal.vue?vue&type=template&id=145e9a80&scoped=true&"
import script from "./classifySingleModal.vue?vue&type=script&lang=js&"
export * from "./classifySingleModal.vue?vue&type=script&lang=js&"
import style0 from "./classifySingleModal.vue?vue&type=style&index=0&id=145e9a80&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "145e9a80",
  null
  
)

export default component.exports