<style scoped lang="less">
	.singleRowProduct-module {
		.product-list {

			overflow: hidden;
			padding: 0 10px;

			.product-item {
				position: relative;
				float: left;
				width: 172px;
				height: 265px;
				background: #fff;
				margin-right: 10px;
				margin-top: 10px;
				box-shadow: 0 3px 2px 0px rgba(99, 99, 99, 0.1);
				border-radius: 6px;

				&:nth-child(2n) {
					margin-right: 0;
				}
				&:nth-child(1){
					margin-top: 0;
				}
				&:nth-child(2){
					margin-top: 0;
				}

				.img {
					width: 172px;
					height: 172px;
					border-radius: 6px 6px 0 0;
					overflow: hidden;

					img {
						display: block;
						width: 100%;
					}
				}

				.name {
					margin: 5px 5px 10px;
					font-size: 14px;
					line-height: 16px;
					height: 32px;

					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}

				.pro-info {
					display: flex;
					margin: 0 5px;
					align-items: center;

					.left {
						flex: 1 1 auto;
						color: #ff547b;
						font-weight: bold;
						font-size: 16px;
						display: flex;
						align-items: center;
						
						.old{
							font-size: 12px;
							color: #999;
							text-decoration: line-through;
							margin-left: 5px;
							font-weight: 400;
						}
					}

					.right {
						flex: 0 0 auto;
						width: 15px;
						height: 14px;

						img {
							display: block;
							width: 100%;
						}
					}
				}

				.tag {
					width: 60px;
					height: 16px;
					line-height: 16px;
					color: #fff;
					background: #ff547b;
					border-radius: 8px;
					font-size: 12px;
					text-align: center;
					    margin-bottom: 7px;
					    margin-left: 5px;
				}
			}

		}
	}

	.haveSpace {
		margin-bottom: 10px;
	}
</style>

<template>
	<div class="singleRowProduct-module" @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;"
	 :class="{haveSpace: !data.hideSpace}">
		<module-name :name="name"></module-name>
		<controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')"
		 @remove="$emit('remove')"></controler>

		<div>
			<!-- <img style="display: block; margin: 0 auto 10px;" :src="$store.state.pageManageConfig.staticImage.guessyourlike" /> -->

			<div class="product-list">
				<div class="product-item" v-for="i in 2" :key="i">
					<div class="img">
						<img :src="normalData.url" />
					</div>
					<p class="name">{{normalData.name}}</p>
					<div class="tag">满减/送</div>
					<div class="pro-info">
						<div class="left">
							￥{{normalData.price}}
							<div class="old">￥120</div>
						</div>
						<div class="right">
							<img src="https://api.static.huibogoulive.cn/images/melyshop/page-manage-cart-icon.png" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>

	</div>
</template>

<script>
	import moduleName from '../../moduleName';
	import controler from '../../controler';
	import edit from './edit';



	export default {
		name: 'normal-module',
		components: {
			moduleName,
			controler,
			edit,

		},
		props: {
			name: {
				type: String,
				default: ''
			},
			data: {
				type: Object,
				default: () => {
					return {}
				}
			},

		},
		data() {
			return {

				btnType: 1, //按钮三种样式
				normalData: {
					url: this.$store.state.pageManageConfig.staticImage.noPic,
					name: '此处是商品名称-此处是商品名称',
					price: 99.00,
					marketPrice: 100.00,
				}
			};
		},
		methods: {
			edit() {
				this.$emit('visibleChange', this.data.visible)
			},

			close() {
				this.$emit('visibleChange', false)
			}

		},
		mounted() {},
		watch: {

		}
	};
</script>
