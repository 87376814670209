<style scoped lang="less">
	.search{
        flex: 0 0 auto;
        margin-bottom: 10px;
    }
    .tab-box{
        flex: 1 1 auto;
        margin-bottom: 10px;
    }
    .pagination{
        flex: 0 0 auto;
    }
</style>
<template>
	<el-dialog :visible="show" width='990px' title="选择优惠券" :append-to-body="true" @close="cancel">

		<div class="search">
			<span style="padding-right: 10px;">关键字：</span>
			<el-input v-model="keyword" style="width:200px;margin-right:10px" size="mini" placeholder="优惠券名称" />
			<el-button type="primary" size="mini" @click="pageIndex=1;getData();">搜索</el-button>
		</div>

		<div class="tab-box">
			<el-table :data="data" v-loading="loading" size="mini" :row-key="getRowKey" ref="tab" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
				<el-table-column prop="couponName" label="优惠券" width="250px"></el-table-column>
				<!-- <el-table-column label="类型" width="100px">
					<template slot-scope="scope">
						<span v-if="scope.row.UseType===0">满减券</span>
						<span v-if="scope.row.UseType===1">打折券</span>
						<span v-if="scope.row.UseType===2">随机金额券</span>
					</template>
				</el-table-column> -->
				<el-table-column prop="faceMoney" label="面值" width="100px"></el-table-column>
				<el-table-column label="优惠内容" width="150px">
					<template slot-scope="scope">
						<span v-if="scope.row.shold==1">满{{scope.row.sholdMoney}}元,</span>
						<span v-else>无门槛,</span>
						<span>减{{scope.row.faceMoney}}元</span>
					</template>
				</el-table-column>
				<el-table-column label="适用商品" width="140px">
					<template slot-scope="scope">
						<span v-if="scope.row.applyType===0">全部商品可用</span>
						<span v-else>部分商品可用</span>
					</template>
				</el-table-column>
				<el-table-column label="限领次数" width="100px">
					<template slot-scope="scope">
						<span v-if="scope.row.getType==1">限领{{scope.row.getNum}}次</span>
						<span v-else>不限制</span>
					</template>
				</el-table-column>
				<el-table-column prop="num" label="剩余数量" width="100px"></el-table-column>
			</el-table>
		</div>

		<div class="pagination" style="justify-content: flex-end;display: flex;">
			<el-pagination :current-page="pageIndex" :total="total" :page-size="pageSize" @current-change="pageChange"></el-pagination>
		</div>

		<div slot="footer">
			<el-button type="primary" @click="btnSure">确定</el-button>
		</div>

	</el-dialog>
</template>
<script>
	import {
		findComCoupon,
	} from '../../api/wyUsedInterface.js'
	import config from '@/config/index'

	export default {
		components: {},
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			isSingle: { //是否是单选
				type: Boolean,
				default: false
			},
			selectedList: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		data() {
			return {
				show: false,
				keyword: '',
				pageIndex: 1,
				pageSize: 10,
				total: 0,
				data: [],
				loading: false,
				selectionData: [],
				imgUrl: config.IMG_BASE
			};
		},
		methods: {
			cancel() {
				this.$emit('cancel');
			},
			async getData() {
				console.log("---selectedList----", this.selectedList);
				const params = {
					couponName: this.keyword,
					pageNo: this.pageIndex,
					pageSize: this.pageSize
				}

				this.loading = true;

				const res = await findComCoupon(params);

				this.data = res.data.records;
				this.total = res.data.records.length;
				this.loading = false;

				if (!this.isReady) {
					if (this.$refs['tab']) {
						this.selectedList.map(item => {
							this.$refs['tab'].toggleRowSelection(item, true);
						})
						this.isReady = true;
					}
				}

			},
			getRowKey(row) {
				return row.id
			},
			pageChange(value) {
				this.pageIndex = value;
				this.getData();
			},
			//当前页全选
			handleSelectionChange(val) {
				this.selectionData = val;
			},
			// selectionChange(selection){
			//     this.selectionData = selection;
			// },
			// selectedRow(record, row){
			//     this.singleData = row;
			// },
			btnSure() {
				// if (this.isSingle && this.selectedData.length > this.disableList.length+1){
				//     this.$Message.error('修改产品只能单选！');
				//     return;
				// }
				console.log("-------", this.selectionData);
				this.$emit('change', this.selectionData);
				this.cancel();
			}
		},
		watch: {
			visible(value) {
				this.show = value;

				if (value) {
					if (this.$refs['tab']) {
						this.$refs['tab'].clearSelection()
						this.selectedList.map(item => {
							this.$refs['tab'].toggleRowSelection(item, true);
						})
					}
					this.getData();
				}
			}
		},
		mounted() {

		},
		created() {

		},
		dispatch() {

		}
	};
</script>
