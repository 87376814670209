<style scoped lang="less">

    .singleRowProduct-module{
        
        .coupon-list{
   //          padding: 0 10px;
			// display: flex;
			
			.item {
				background-color: #fff;
				padding: 15px;
				display: flex;
				align-items: center;
				margin-top: 10px;
			
				&:first-child {
					margin-top: 0;
				}
			
				.img {
					flex: 0 0 auto;
					width: 80px;
					overflow: hidden;
					margin-right: 15px;
					
					img{
						display: block;
						width: 100%;
					}
				}
			
				.right {
					flex: 1 1 auto;
					overflow: hidden;
					display: flex;
					min-height: 80px;
					flex-direction: column;
					justify-content: space-around;
			
					.room-name {
						font-size: 15px;
						font-weight: bold;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					
					.not-start{
						display: flex;
						align-items: center;
						
						.time{
							font-size: 11px;
							color: #999;
							margin-left: 5px;
						}
					}
			
					.state {
						background: #fb4a4a;
						color: #fff;
						display: inline-flex;
						align-items: center;
						border-radius: 3px;
						padding: 2px 5px;
						font-size: 11px;
			
						img {
							display: block;
							width: 8px;
							margin-right: 3px;
						}
					}
			
					.name {
						display: flex;
						align-items: center;
						font-size: 12px;
						color: #999;
			
						img {
							width: 13px;
							height: 13px;
							margin-right: 3px;
						}
					}
				}
				
				.btn{
					flex: 0 0 auto;
					margin-left: 15px;
					min-height: 80px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					
					img{
						display: block;
						width: 37px;
						height: 37px;
					}
				}
			}
        }
        
    }
    .haveSpace{
        margin-bottom: 10px;
    }
    
</style>

<template>
    <div class="singleRowProduct-module" @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;" :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        <!-- <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  /> -->
        
        <div class="coupon-list">  <!-- @click="edit();data.visible=true" -->
			<div v-if="data.list.length">
				<div class="item" v-for="item in data.list" :key="item.Id">
					<div class="img">
						<img :src="item.AnchorImgUrl" alt="">
					</div>
					<div class="right">
						<div class="room-name">{{item.RoomName}}</div>
						<div>
							<div class="state" v-if="item.State == 1">
								<img src="https://api.static.huibogoulive.cn/images/melyshop/live-icon-6.gif" mode="widthFix"></img>
								<div>直播中</div>
							</div>
							<div class="not-start" v-if="item.State==0">
								<div class="state" style="background: #30d5a0;">
									<img src="https://api.static.huibogoulive.cn/images/melyshop/live-icon-2.png" mode="widthFix"></img>
									<div>预告</div>
								</div>
								<div class="time" v-if="item.timeStr">{{item.timeStr}}</div>
							</div>
							
							<div class="state" style="background: #999999;" v-if="item.State == 2">
								<!-- <img src="https://api.static.huibogoulive.cn/images/melyshop/live-icon-2.png" mode="widthFix"></img> -->
								<div>已结束</div>
							</div>
						</div>
					
						<div class="name">
							<img src="https://api.static.huibogoulive.cn/images/melyshop/live-icon-3.png"></img>
							{{item.AnchorNickName}}
						</div>
					</div>
					<div class="btn">
						<div></div>
						<img src="https://api.static.huibogoulive.cn/images/melyshop/live-icon-4.png"></img>
					</div>
				</div>
			</div>
			<div class="item" v-else>
				<div class="img">
					<img src="https://api.static.huibogoulive.cn/images/dyj/no-pic.png" alt="">
				</div>
				<div class="right">
					<div class="room-name">此处显示直播间标题</div>
					<div>
						<div class="state">
							<img src="https://api.static.huibogoulive.cn/images/melyshop/live-icon-6.gif" mode="widthFix"></img>
							<div>直播中</div>
						</div>
					</div>
				
					<div class="name">
						<img src="https://api.static.huibogoulive.cn/images/melyshop/live-icon-3.png"></img>
						主播昵称
					</div>
				</div>
				<div class="btn">
					<div></div>
					<img src="https://api.static.huibogoulive.cn/images/melyshop/live-icon-4.png"></img>
				</div>
			</div>
        </div>
		
		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>
		
    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';

export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
		// console.log(this.data)
    },
    watch: {
        data(value){
            // console.log(value)
        }
    }
};
</script>

